import { createSelector, Selector } from '@reduxjs/toolkit';

import {
  PaymentType,
  AcceptedCurrencyCode,
  TipScheme,
  LineItemType,
  CardType,
  Checkout,
  VoluntaryContributionConfig,
  VoluntaryContributionConfigValueType,
} from '../../../../../types/__generated-graphQL__';
import { TipJarConfigSet, TipJarSelections } from '../../../../../types/tipjarConfig';
import APP_SETTINGS from '../../../../config';
import { getTipJarDefaultValueAdjustment } from '../../../config/hacks';
import { CONSENT_LOCATION_ID } from '../../../config/preferences';
import { lowTipAboveUpperLimitInPennies } from '../../../config/tipjar/tipjarConfigDefaults';
import { pointsToDollars } from '../../../modules/AmexPayWithPoints/AmexPWP.utils';
import { isVoluntaryContributionConfig } from '../../../utils/typeGuards';
import { roundNumber } from '../../../utils/value';
import {
  getGQLDefaultDonationValue,
  getGQLAdditionalDataCurrencyCode,
  getGQLAdditionalDataCause,
} from './additionalData.selectors';
import { getGQLCustomAttributesCurrencyCode } from './customAttributes.selectors';
import { getGQLIsMatchedInSwiftAid } from './giftAid.selectors';
import {
  getGQLIsRecurringNotDirectDebit,
  getGQLLineItemValueInPounds,
  getGQLSupportsGiftAid,
  getGQLOrderType,
  getGQLPage,
  getGQLCauseId,
  getGQLisTipJarEnabled,
  getGQLIsDonorPaysFeesEnabled,
  getGQLCauseSourceRef,
} from './lineItem.selectors';
import {
  getGQLCurrencyCode,
  getGQLAppliedTipScheme,
  getGQLTotalAmountInPounds,
  getGQLSupportedCardTypes,
  getGQLIsAmexPayWithPointsAvailable,
  getGQLSupportedPaymentTypesForCurrency,
  getGQLVoluntaryContributionConfig,
} from './order.selectors';
import {
  getGQLSelectedPaymentType,
  getGQLIsWalletPaymentTypeSelected,
  getGQLIsDirectDebitSelected,
  getGQLPaymentCardDetailsCardType,
  getGQLPaymentCardDetailsPoints,
  getGQLPaymentCardDetails,
} from './payment.selectors';

export const getGQLSavedOrDefaultDonationValue = createSelector(
  getGQLLineItemValueInPounds,
  getGQLDefaultDonationValue,
  (lineItemValueInPounds, defaultDonationValue) => {
    if (lineItemValueInPounds > 0) return lineItemValueInPounds.toString();
    return defaultDonationValue?.toString();
  },
);

export const getGQLShouldSkipGiftAidDetails = createSelector(
  getGQLSelectedPaymentType,
  getGQLIsWalletPaymentTypeSelected,
  getGQLIsMatchedInSwiftAid,
  (selectedPaymentType, isWalletPaymentTypeSelected, isMatchedInSwiftAid) =>
    selectedPaymentType === PaymentType.PaymentCard || isWalletPaymentTypeSelected || isMatchedInSwiftAid,
);

export const getGQLSavedOrDefaultCurrencyCode = createSelector(
  getGQLCurrencyCode,
  getGQLAdditionalDataCurrencyCode,
  getGQLCustomAttributesCurrencyCode,
  (currencyCode, additionalDataCurrencyCode, customAttributesCurrencyCode) => {
    if (currencyCode) return currencyCode;
    if (additionalDataCurrencyCode) return additionalDataCurrencyCode;
    if (customAttributesCurrencyCode) return customAttributesCurrencyCode;
    return AcceptedCurrencyCode.Gbp;
  },
);

export const getGQLIsDonorPayingFees = createSelector(
  getGQLIsDonorPaysFeesEnabled,
  getGQLAppliedTipScheme,
  getGQLTotalAmountInPounds,
  (isDonorPaysFeesEnabled, appliedTipScheme, totalAmount) => {
    if (!isDonorPaysFeesEnabled) return false;
    if (totalAmount && appliedTipScheme !== TipScheme.Dpf1_2) return false;
    return true;
  },
);

export const getGQLIsRecurringOrDirectDebit = createSelector(
  getGQLIsRecurringNotDirectDebit,
  getGQLIsDirectDebitSelected,
  (isRecurringNotDirectDebit, isDirectDebit) => isRecurringNotDirectDebit || isDirectDebit,
);

export const getGQLDisplayGiftAid = createSelector(
  getGQLSupportsGiftAid,
  getGQLSavedOrDefaultCurrencyCode,
  getGQLSelectedPaymentType,
  (supportsGiftAid, savedOrDefaultDonationCurrencyCode, selectedPaymentType) =>
    Boolean(
      supportsGiftAid &&
        savedOrDefaultDonationCurrencyCode === AcceptedCurrencyCode.Gbp &&
        selectedPaymentType !== PaymentType.Crypto,
    ),
);

export const getGQLPageOwnerName = createSelector(getGQLOrderType, getGQLPage, (orderType, page) => {
  let pageOwnerName = page?.owner?.name;
  // Note: "unclaimed" pages for Digital Services projects have the name "- -", which we never want to be visible in the checkout
  if (pageOwnerName === '- -') pageOwnerName = null;
  return orderType &&
    [LineItemType.DonationCharityFrp, LineItemType.DonationCampaignFrp, LineItemType.DonationCrowdfunding].includes(
      orderType,
    ) &&
    pageOwnerName
    ? pageOwnerName
    : null;
});

export const getGQLCharityName = createSelector(getGQLOrderType, getGQLAdditionalDataCause, (orderType, cause) => {
  const charityName = cause?.name;
  return orderType && orderType !== LineItemType.DonationCrowdfunding && charityName ? charityName : null;
});

export const getGQLCampaignName = createSelector(getGQLOrderType, getGQLPage, (orderType, page) => {
  const campaignName = page?.title;
  return orderType && orderType === LineItemType.DonationCampaignDirect && campaignName ? campaignName : null;
});

export const getGQLCharityOrPageOwnerName = createSelector(
  getGQLCharityName,
  getGQLPageOwnerName,
  (charityName, pageOwnerName) => charityName ?? pageOwnerName,
);

export const getGQLPageOwnerOrCharityName = createSelector(
  getGQLPageOwnerName,
  getGQLCharityName,
  (pageOwnerName, charityName) => pageOwnerName ?? charityName,
);

export const getGQLIsAmexSupported = createSelector(
  getGQLSupportedCardTypes,
  getGQLOrderType,
  (supportedCardTypes, orderType) => {
    return supportedCardTypes?.includes(CardType.AmericanExpress) && orderType !== LineItemType.DonationCrowdfunding;
  },
);

export const getGQLLegacyCharityId = createSelector(
  getGQLAdditionalDataCause,
  getGQLOrderType,
  (additionalDataCause, causeType) => {
    if (
      [
        LineItemType.DonationCharityDirect,
        LineItemType.DonationCharityFrp,
        LineItemType.DonationCampaignDirect,
        LineItemType.DonationCampaignFrp,
      ].includes(causeType!)
    ) {
      return additionalDataCause?.legacyId;
    }
    return;
  },
);

export const getGQLIsValidPaymentMethod = createSelector(
  getGQLSelectedPaymentType,
  getGQLPaymentCardDetails,
  getGQLSupportedPaymentTypesForCurrency,
  getGQLSupportedCardTypes,
  (selectedPaymentType, selectedPaymentCard, supportedPaymentTypeForCurrency, supportedCardTypes) => {
    if (!selectedPaymentType) {
      return false;
    }
    if (!supportedPaymentTypeForCurrency?.includes(selectedPaymentType)) {
      return false;
    }
    if (
      selectedPaymentType !== PaymentType.PaymentCard ||
      (selectedPaymentType === PaymentType.PaymentCard &&
        selectedPaymentCard?.cardType &&
        (supportedCardTypes?.includes(selectedPaymentCard.cardType) ||
          selectedPaymentCard.cardType === CardType.Unknown))
    ) {
      return true;
    }
    return false;
  },
);

export const getGQLShouldRouteToPWPPage = createSelector(
  getGQLSelectedPaymentType,
  getGQLIsAmexPayWithPointsAvailable,
  getGQLPaymentCardDetailsCardType,
  getGQLPaymentCardDetailsPoints,
  (selectedPaymentType, isAmexPayWithPointsAvailable, paymentCardType, paymentCardPoints) => {
    if (
      selectedPaymentType !== PaymentType.PaymentCard ||
      !isAmexPayWithPointsAvailable ||
      paymentCardType !== CardType.AmericanExpress ||
      !paymentCardPoints?.isEligible
    )
      return false;

    return pointsToDollars(paymentCardPoints?.totalPoints ?? 0, paymentCardPoints?.conversionRate ?? 0.007) >= 1;
  },
);

export const getGQLIsGoCardlessInstantBankPaymentAvailable = createSelector(
  getGQLIsRecurringOrDirectDebit,
  getGQLSupportedPaymentTypesForCurrency,
  getGQLLegacyCharityId,
  (isRecurringOrDirectDebit, supportedPaymentTypes, legacyCharityId) => {
    if (APP_SETTINGS.DISABLE_GCIBP && legacyCharityId !== '2050') return false;
    if (isRecurringOrDirectDebit) return false;
    return supportedPaymentTypes?.includes(PaymentType.GoCardlessInstantBankPayment);
  },
);

export const getGQLIsPayPalAvailable = createSelector(
  getGQLIsRecurringOrDirectDebit,
  getGQLSupportedPaymentTypesForCurrency,
  (isRecurring, supportedPaymentTypes) => {
    if (isRecurring) return false;
    return supportedPaymentTypes?.includes(PaymentType.BraintreePayPal);
  },
);

export const getGQLIsGooglePayAvailable = createSelector(
  getGQLIsRecurringOrDirectDebit,
  getGQLSupportedPaymentTypesForCurrency,
  (isRecurring, supportedPaymentTypes) => {
    if (isRecurring) return false;
    return supportedPaymentTypes?.includes(PaymentType.BraintreeGooglePay);
  },
);

export const getGQLIsApplePayAvailable = createSelector(
  getGQLIsRecurringOrDirectDebit,
  getGQLSupportedPaymentTypesForCurrency,
  (isRecurring, supportedPaymentTypes) => {
    if (isRecurring) return false;
    return supportedPaymentTypes?.includes(PaymentType.BraintreeApplePay);
  },
);

export const getGQLIsVenmoAvailable = createSelector(
  getGQLIsRecurringOrDirectDebit,
  getGQLSupportedPaymentTypesForCurrency,
  (isRecurring, supportedPaymentTypes) => {
    if (isRecurring) return false;
    return supportedPaymentTypes?.includes(PaymentType.BraintreeVenmo);
  },
);

export const getGQLIsAnyAlternativePaymentsAvailable = createSelector(
  getGQLIsApplePayAvailable,
  getGQLIsGooglePayAvailable,
  getGQLIsPayPalAvailable,
  getGQLIsVenmoAvailable,
  (isApplePayAvailable, isGooglePayAvailable, isPayPalAvailable, isVenmoAvailable) =>
    Boolean(isApplePayAvailable || isGooglePayAvailable || isPayPalAvailable || isVenmoAvailable),
);

export const getGQLTipJarValues = (
  dynamicDonationAmountInPounds: string,
  isCryptoTransactionTypeSelected: boolean,
  tipJarValuesOverride?: TipJarConfigSet,
) =>
  createSelector(
    getGQLisTipJarEnabled,
    getGQLOrderType,
    getGQLCauseId,
    getGQLLineItemValueInPounds,
    getGQLVoluntaryContributionConfig,
    getGQLSelectedPaymentType,
    (
      isTipJarEnabled,
      causeType,
      causeId,
      stateDonationAmountInPounds,
      voluntaryContributionConfig,
      selectedPaymentType,
    ) => {
      if (!isTipJarEnabled || !causeType) return null;

      const donationAmountInPounds = Number(dynamicDonationAmountInPounds) || stateDonationAmountInPounds;
      let donationAmountInPennies = donationAmountInPounds * 100;

      let tipJarValues: TipJarConfigSet | null = isVoluntaryContributionConfig(voluntaryContributionConfig)
        ? voluntaryContributionConfig
        : null;

      if (tipJarValuesOverride) {
        tipJarValues = tipJarValuesOverride;
      }

      if (!tipJarValues) {
        return null;
      }

      // Forces low-value (< 10 fiat) crypto transactions to use % based config used for donations of 10 fiat or more
      if (
        (isCryptoTransactionTypeSelected || selectedPaymentType === PaymentType.Crypto) &&
        donationAmountInPennies < lowTipAboveUpperLimitInPennies
      ) {
        donationAmountInPennies = lowTipAboveUpperLimitInPennies;
      }

      const tipjarValuesForAmountInPennies: VoluntaryContributionConfig = {
        ...(tipJarValues.find(
          (value: VoluntaryContributionConfig) =>
            donationAmountInPennies >= value.from &&
            (donationAmountInPennies <= value.to || value.to === Number.MAX_VALUE),
        ) || tipJarValues[0]),
      };
      const tipjarValuesForAmountInPounds: VoluntaryContributionConfig =
        tipjarValuesForAmountInPennies.valueType === VoluntaryContributionConfigValueType.Percentage
          ? {
              ...tipjarValuesForAmountInPennies,
              from: tipjarValuesForAmountInPennies.from / 100,
              to: tipjarValuesForAmountInPennies.to / 100,
            }
          : {
              ...tipjarValuesForAmountInPennies,
              defaultValue: tipjarValuesForAmountInPennies.defaultValue / 100,
              from: tipjarValuesForAmountInPennies.from / 100,
              to: tipjarValuesForAmountInPennies.to / 100,
              values: tipjarValuesForAmountInPennies.values.map((value: number) => value / 100),
            };

      const tipJarDefaultValueAdjustment = getTipJarDefaultValueAdjustment(causeId);

      if (
        tipJarDefaultValueAdjustment &&
        tipjarValuesForAmountInPounds.valueType === VoluntaryContributionConfigValueType.Percentage
      ) {
        tipjarValuesForAmountInPounds.defaultValue = tipJarDefaultValueAdjustment;
      }

      return tipjarValuesForAmountInPounds;
    },
  );

export const getGQLTipJarInitialValues = (
  tipScheme: TipScheme | null,
  tipValue: number,
  donationValue: string,
  isCryptoTransactionTypeSelected: boolean,
  tipJarValuesOverride?: TipJarConfigSet,
): Selector<Checkout | undefined, TipJarSelections | null> =>
  createSelector(
    getGQLTipJarValues(donationValue, isCryptoTransactionTypeSelected, tipJarValuesOverride),
    tipJarValues => {
      if (!tipJarValues) return null;

      const isTipValueSubmitted = Boolean(tipScheme);

      if (!isTipValueSubmitted) {
        return { dropdownValue: tipJarValues.defaultValue };
      }

      const dropdownValue = tipJarValues.values.find((value: number) => {
        const tipAmountCalculation = roundNumber(Number(donationValue) * (value / 100));
        return (
          (tipJarValues.valueType === VoluntaryContributionConfigValueType.Percentage
            ? tipAmountCalculation
            : value) === tipValue
        );
      });

      return { dropdownValue, noDropdownValue: typeof dropdownValue === 'undefined' };
    },
  );

export const getGQLConsentLocationId = (data?: Checkout) => {
  const orderType = getGQLOrderType(data);

  return CONSENT_LOCATION_ID[orderType!];
};

// TODO Rename this to be getGQLCampaignLegacyIdOrSourceRef
export const getGQLRef = (data?: Checkout) => {
  const additionalDataCause = getGQLAdditionalDataCause(data);
  const causeSourceRef = getGQLCauseSourceRef(data);
  const orderType = getGQLOrderType(data);
  const legacyId = additionalDataCause && additionalDataCause.legacyId;

  if (orderType === LineItemType.DonationCampaignDirect) return legacyId;

  return causeSourceRef;
};
