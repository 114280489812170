import { useSelector } from 'react-redux';

import { applePay, googlePay, paypal, venmo } from '../../hooks/useInitialiseBraintree';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLIsApplePayAvailable,
  getGQLIsGoCardlessInstantBankPaymentAvailable,
  getGQLIsGooglePayAvailable,
  getGQLIsPayPalAvailable,
  getGQLIsVenmoAvailable,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLSavedPaymentCards } from '../../redux/checkoutApiSlice/selectors/transactingUser.selectors';
import { getIsBraintreeDataCollectorInitialised } from '../../redux/session/session.selectors';

export function usePaymentTypesAvailable() {
  const { data } = useFetchCheckoutQuery();
  const isBraintreeDataCollectorInitialised = useSelector(getIsBraintreeDataCollectorInitialised);

  const isGoCardlessInstantBankPaymentSupportedForCurrency = getGQLIsGoCardlessInstantBankPaymentAvailable(data);

  const isApplePaySupportedForCurrency = getGQLIsApplePayAvailable(data);
  const isApplePayBrowserEnabled = applePay.enabled && applePay.instance;
  const isApplePayAvailable = isApplePaySupportedForCurrency && isApplePayBrowserEnabled;

  const isGooglePaySupportedForCurrency = getGQLIsGooglePayAvailable(data);
  const isGooglePayBrowserEnabled = googlePay.enabled && googlePay.instance;
  const isGooglePayAvailable = isGooglePaySupportedForCurrency && isGooglePayBrowserEnabled;

  const isPayPalSupportedForCurrency = getGQLIsPayPalAvailable(data);
  const isPayPalBrowserEnabled = paypal.enabled && paypal.instance;
  const isPayPalAvailable = isPayPalSupportedForCurrency && isPayPalBrowserEnabled;

  const isVenmoSupportedForCurrency = getGQLIsVenmoAvailable(data);
  const isVenmoBrowserEnabled = venmo.enabled && venmo.instance && isBraintreeDataCollectorInitialised;
  const isVenmoAvailable = isVenmoSupportedForCurrency && isVenmoBrowserEnabled;

  const savedPaymentCards = getGQLSavedPaymentCards(data);

  return {
    isGoCardlessInstantBankPaymentAvailable: isGoCardlessInstantBankPaymentSupportedForCurrency,
    isApplePayAvailable,
    isGooglePayAvailable,
    isPayPalAvailable,
    isVenmoAvailable,
    isAnyAlternativePaymentsAvailable: Boolean(
      isApplePayAvailable ||
        isPayPalAvailable ||
        isVenmoAvailable ||
        isGooglePayAvailable ||
        isGoCardlessInstantBankPaymentSupportedForCurrency,
    ),
    hasSavedPaymentCards: Boolean(savedPaymentCards?.length),
  };
}
