import { gql } from 'graphql-tag';

import { Checkout, UpdateCheckoutSelectedCardInput } from '../../../../types/__generated-graphQL__';
import { checkoutDetails } from '../../graphQL/fragmentCheckoutDetails';
import { checkoutApiSlice } from './checkoutApiSlice';
import { queryCheckoutApi } from './queryCheckoutApi';

export const mutateSelectedCard = checkoutApiSlice.injectEndpoints({
  endpoints: builder => ({
    mutateSelectedCard: builder.mutation<Checkout, Omit<UpdateCheckoutSelectedCardInput, 'checkoutId'>>({
      query(input) {
        return {
          variables: {
            input,
          },
          document: gql`
            ${checkoutDetails}
            mutation updateCheckoutSelectedCard($input: UpdateCheckoutSelectedCardInput!) {
              updateCheckoutSelectedCard(input: $input) {
                ...checkoutDetails
              }
            }
          `,
        };
      },
      transformResponse: (response: { updateCheckoutSelectedCard: Checkout }) => response.updateCheckoutSelectedCard,
      async onQueryStarted(_input, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(queryCheckoutApi.util.updateQueryData('fetchCheckout', undefined, () => data));
        } catch (err) {
          // Do nothing
        }
      },
    }),
  }),
});

export const { useMutateSelectedCardMutation } = mutateSelectedCard;
