import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { PaymentType, TipScheme } from '../../../../types/__generated-graphQL__';
import { VoluntaryContributionTooltip } from '../../components/VoluntaryContributionTooltip/VoluntaryContributionTooltip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCharityName, getGQLIsDonorPayingFees } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLDisplayGiftAid } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLGiftAidIsClaimed } from '../../redux/checkoutApiSlice/selectors/giftAid.selectors';
import { getGQLIsBBGF, getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLAppliedTipScheme,
  getGQLCurrencyCode,
  getGQLTipAmountInPounds,
  getGQLTotalAmountInPounds,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLSelectedPaymentType } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { formatCurrency } from '../../utils/value';
import CryptoValues from '../Crypto/CryptoValues';
import * as styles from './DonationInformation.css';

type Props = { donationStatus: DonationStatus };

const DonationInformation: FunctionComponent<Props> = ({ donationStatus }) => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();

  const isDonorPayingFees = getGQLIsDonorPayingFees(data);
  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const apiDonationCurrencyCode = getGQLCurrencyCode(data);
  const totalAmountInPounds = getGQLTotalAmountInPounds(data);
  const tipAmountInPounds = getGQLTipAmountInPounds(data);
  const isBBGF = getGQLIsBBGF(data);
  const giftAidIsClaimed = getGQLGiftAidIsClaimed(data);
  const giftAidDisplayed = getGQLDisplayGiftAid(data);
  const charityName = getGQLCharityName(data);
  const isTip = isDonorPayingFees || tipAmountInPounds > 0;
  const appliedTipScheme = getGQLAppliedTipScheme(data);
  const selectedPaymentType = getGQLSelectedPaymentType(data);

  const isCrypto = selectedPaymentType === PaymentType.Crypto;
  let tipLabel: string = t('tipToJustGiving');
  if (appliedTipScheme === TipScheme.Dpf1_2) {
    tipLabel = t('voluntaryContribution');
  } else if (isBBGF) {
    tipLabel = t('thankyou|BBGF|tip|heading', { ns: 'tipJar' });
  }
  if (isCrypto && donationStatus !== 'succeeded') return null;

  if (!totalAmountInPounds) return null;

  const donationAmount = formatCurrency(apiDonationCurrencyCode, lineItemValueInPounds);
  const tipAmount = formatCurrency(apiDonationCurrencyCode, tipAmountInPounds);
  const totalAmount = formatCurrency(apiDonationCurrencyCode, totalAmountInPounds);

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.heading}>{t('yourDonationTitle')}</h2>
      <dl className={styles.infoWrapper}>
        <dt className={styles.title}>
          {isBBGF
            ? t('thankyou|donationValue', { causeName: charityName, ns: 'bbgf' })
            : t('donationValueLabel', { ns: 'amount' })}
        </dt>
        <dd className={styles.value} data-qa="donation-amount">
          {isCrypto ? <CryptoValues isThankYouPage fiatAmount={lineItemValueInPounds} /> : donationAmount}
        </dd>
        {isTip && (
          <>
            <dt data-qa="donorFee" className={styles.title}>
              {tipLabel}
              {['timeout', 'succeeded', 'directdebit_succeeded'].includes(donationStatus) && (
                <VoluntaryContributionTooltip tooltipButtonClassName={styles.tooltip} />
              )}
            </dt>
            <dd className={styles.value}>
              {isCrypto ? <CryptoValues isThankYouPage fiatAmount={tipAmountInPounds} /> : tipAmount}{' '}
            </dd>
          </>
        )}
        {giftAidDisplayed && (
          <>
            <dt className={styles.title}>{t('thankyou|yourdonation|giftAidTitle', { ns: 'giftAid' })}</dt>
            <dd className={styles.value}>{t(`${giftAidIsClaimed ? 'yes' : 'no'}`)}</dd>
          </>
        )}
        <dt className={classNames(styles.title, styles.total)}>{isBBGF ? t('totalPayment') : t('total')}</dt>
        <dd data-qa="totalAmountToPay" className={classNames(styles.value, styles.total)}>
          {isCrypto ? <CryptoValues isThankYouPage fiatAmount={totalAmountInPounds} /> : totalAmount}
        </dd>
      </dl>
    </section>
  );
};

export default DonationInformation;
