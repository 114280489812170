import React, { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import classNames from 'classnames';

import { AcceptedCurrencyCode } from '../../../../../types/__generated-graphQL__';
import { AmountPageFormValues, TransactionTypes } from '../../../../../types/formValues';
import { sprinkles } from '../../../../styles/sprinkles.css';
import { PAGES } from '../../../config/pages';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { getIsLoggedIn } from '../../../redux/auth/auth.selectors';
import { useFetchCheckoutQuery } from '../../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLDonorDisplayName,
  getGQLLineItemValueInPounds,
  getGQLMessage,
} from '../../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLIsDirectDebitSelected } from '../../../redux/checkoutApiSlice/selectors/payment.selectors';
import { AccordionPageCurrentStep } from '../AccordionPageCurrentStep';
import { AccordionPageFilledStep } from '../AccordionPageFilledStep';
import { AccordionPageLoadingStep } from '../AccordionPageLoadingStep';
import { AccordionPageNewStep } from '../AccordionPageNewStep';
import { AccordionPagePreviewEmerge } from '../AccordionPagePreviewEmerge';
import { AccordionPageSectionWrapper } from '../AccordionPageSectionWrapper';
import type { PageSectionProps } from './AccordionSection';

const MessageStepPreview: FunctionComponent = () => {
  const { t } = useTranslation();

  const isLoggedIn = useAppSelector(getIsLoggedIn);

  const { data } = useFetchCheckoutQuery();
  const donorDisplayName = getGQLDonorDisplayName(data);
  const apiMessage = getGQLMessage(data);
  const lineItemValue = getGQLLineItemValueInPounds(data);
  const currency = getGQLCurrencyCode(data);

  if (lineItemValue && currency && isLoggedIn) {
    return (
      <AccordionPageFilledStep
        title={t('message|filled|title', { ns: 'ab' })}
        routeTo={'MessagePage'}
        a11yRouteText={t('summary|messageEditScreenReader', { ns: 'message' })}
      >
        {!donorDisplayName && !apiMessage ? (
          <div className={sprinkles({ color: 'light' })}>{t('message|filled|noMessage', { ns: 'ab' })}</div>
        ) : (
          <>
            {donorDisplayName}
            <div className={classNames({ [sprinkles({ color: 'light' })]: donorDisplayName })}>{apiMessage}</div>
          </>
        )}
      </AccordionPageFilledStep>
    );
  }

  return <AccordionPageNewStep>{t('message|title', { ns: 'ab' })}</AccordionPageNewStep>;
};

const MessagePageSection: FunctionComponent<PageSectionProps> = ({ children, currentPageCollapsed, isLoading }) => {
  const isCurrentPage = useMatch(`/:pageType?/:pageSlug?/${PAGES.MessagePage}`);

  const { data } = useFetchCheckoutQuery();

  const [formDonationCurrencyCode, formTransactionType] = useWatch<
    AmountPageFormValues,
    ['donationCurrencyCode', 'transactionType']
  >({ name: ['donationCurrencyCode', 'transactionType'] });

  const isDirectDebitSelected = formDonationCurrencyCode
    ? formDonationCurrencyCode === AcceptedCurrencyCode.Gbp && formTransactionType === TransactionTypes.RECURRING
    : getGQLIsDirectDebitSelected(data);

  if (isDirectDebitSelected) return null;

  if (isLoading) return <AccordionPageLoadingStep />;

  if (isCurrentPage) {
    return (
      <li>
        <AccordionPageSectionWrapper>
          {currentPageCollapsed && (
            <AccordionPagePreviewEmerge>
              <MessageStepPreview />
            </AccordionPagePreviewEmerge>
          )}
          <AccordionPageCurrentStep collapsed={currentPageCollapsed}>{children}</AccordionPageCurrentStep>
        </AccordionPageSectionWrapper>
      </li>
    );
  }

  return (
    <li>
      <MessageStepPreview />
    </li>
  );
};

export default MessagePageSection;
