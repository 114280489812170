import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsBBGF } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import * as styles from './BBGFTerms.css';

const BBGFTerms: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isBBGF } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        isBBGF: getGQLIsBBGF(data),
      };
    },
  });

  if (!isBBGF) return null;

  return (
    <>
      <VisuallyHidden id="bbgf-terms">{t('amount|pleaseReadBeyond', { ns: 'bbgf' })}</VisuallyHidden>
      <p className={classNames(styles.bbgfTerms, sprinkles({ marginTop: 'sizeSpacing02', textSize: 's' }))}>
        <Trans i18nKey="amount|terms|main" ns="bbgf">
          When you donate through this platform, you agree to the{' '}
          <a
            href="https://www.justgiving.com/about/info/terms-of-service/blackbaud-giving-fund"
            rel="noopener noreferrer"
            target="_blank"
          >
            Donor Terms of Service
          </a>
          and that you are making a non-refundable, tax-deductible donation to The Blackbaud Giving Fund (BBGF). BBGF
          will make a corresponding grant, within 45 days, to your designated charitable organization in accordance with
          its policies. In
          <CertainCircumstancesTooltipWrapper />
          such organizations may not receive such grants. Charitable organizations appearing on this platform have not
          provided consent for solicitation.
        </Trans>
      </p>
    </>
  );
};

const CertainCircumstancesTooltipWrapper: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Tooltip
      id="tooltip-bbgf"
      buttonText={t('amount|terms|certainCircumstances', { ns: 'bbgf' })}
      contentClassName={sprinkles({ textSize: 's' })}
    >
      {t('amount|terms|tooltip', { ns: 'bbgf' })}
    </Tooltip>
  );
};

export default BBGFTerms;
