import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useDecision } from '@optimizely/react-sdk';

import { getAbTestKey } from '../../config/abTests';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLPage } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import Heart from '../Heart/Heart';
import * as styles from './ExpiryMessage.css';

const ExpiryMessage: FunctionComponent = () => {
  const { endDate } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return { endDate: getGQLPage(data)?.endDate };
    },
  });

  const { t } = useTranslation();

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(endDate);
  const secondDate = new Date();

  const diffDays = Math.round(Math.abs((firstDate.valueOf() - secondDate.valueOf()) / oneDay));
  const shouldShowMessage = endDate && diffDays <= 61;

  const [{ enabled }] = useDecision(getAbTestKey({ abTest: 'PAGE_ENDING_PROMPT', shouldEnrol: shouldShowMessage }));

  if (!enabled) return null;
  if (!shouldShowMessage) return null;

  let message: string = t('pageCloses|today', { ns: 'ab' });

  if (diffDays >= 1) {
    message = t('pageCloses|days', { ns: 'ab', count: diffDays });
  }

  if (diffDays >= 7) {
    message = t('pageCloses|weeks', { ns: 'ab', count: Math.round(diffDays / 7) });
  }

  if (diffDays >= 30) {
    message = t('pageCloses|months', { ns: 'ab', count: Math.round(diffDays / 30) });
  }

  return (
    <div className={styles.container}>
      <Heart />
      <div>{message}</div>
    </div>
  );
};

export default ExpiryMessage;
