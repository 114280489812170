import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { sprinkles } from '../../../styles/sprinkles.css';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCheckoutMode } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLCampaignName,
  getGQLCharityName,
  getGQLPageOwnerName,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLPage } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import { ChromeHeader } from '../ChromeHeader/ChromeHeader';
import Header from '../Header/Header';
import HeaderImage from '../Header/HeaderImage';
import { SourcePagePreview } from '../SourcePagePreview/SourcePagePreview';
import TrustMark from '../TrustMark/TrustMark';
import * as styles from './Layout.css';

type LayoutProps = {
  children: ReactNode;
};
const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();

  const { pageType, checkoutMode, pageOwnerName, campaignName, charityName } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        pageType: getGQLPage(data)?.type,
        checkoutMode: getGQLCheckoutMode(data),
        pageOwnerName: getGQLPageOwnerName(data),
        campaignName: getGQLCampaignName(data),
        charityName: getGQLCharityName(data),
      };
    },
  });
  const isSideBySide = Boolean(pageType);
  const { accordionEnabled } = useAccordionExperiment();

  if (accordionEnabled) {
    return (
      <div
        className={classNames(styles.accordionWrapper, {
          isHeadlessCheckout: checkoutMode === 'Headless',
        })}
      >
        <ChromeHeader />
        <div className={classNames(styles.accordionSbsWrapper, styles.hasAccordion)}>
          <main
            id="maincontent"
            className={classNames(
              accordionEnabled ? styles.accordionCheckoutContainer : styles.checkoutContainer,
              styles.accordionSbsCheckout,
            )}
          >
            <div className={styles.accordionSbsHeader}>
              <Header />
            </div>
            {children}
          </main>
          <aside className={styles.accordionSbsPage}>
            <div className={styles.accordionSbsPageSticky}>
              <div className={styles.accordionSbsPageContents}>
                {isSideBySide ? (
                  <SourcePagePreview />
                ) : (
                  <div className={styles.accordionCauseInfo}>
                    <HeaderImage />
                    <p className={sprinkles({ marginTop: 'sizeSpacing04' })}>
                      {t('donateTo', {
                        name: pageOwnerName ?? campaignName ?? charityName,
                        ns: 'page',
                      })}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.accordionTrustmarks}>
                <TrustMark />
              </div>
            </div>
          </aside>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        isHeadlessCheckout: checkoutMode === 'Headless',
      })}
    >
      <ChromeHeader />
      {isSideBySide ? (
        <div className={classNames(styles.sbsWrapper, styles.hasPageData)}>
          <main id="maincontent" className={classNames(styles.checkoutContainer, styles.sbsCheckout)}>
            <div className={styles.sbsHeader}>
              <Header />
            </div>
            {children}
          </main>
          <aside className={styles.sbsPage}>
            <div className={styles.sbsPageSticky}>
              <SourcePagePreview />
            </div>
          </aside>
        </div>
      ) : (
        <main id="maincontent" className={styles.checkoutContainer}>
          <Header />
          {children}
        </main>
      )}
    </div>
  );
};

export default Layout;
