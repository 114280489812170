import React, { FunctionComponent, Suspense, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import debug from 'debug';

import { Checkout } from '../../types/__generated-graphQL__';
import '../styles/global.css';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import HandleLogin from './components/HandleLogin/HandleLogin';
import HandleRouteChange from './components/HandleRouteChange/HandleRouteChange';
import { OverlayLoader } from './components/OverlayLoader/OverlayLoader';
import { httpErrorMessage } from './config/httpErrorMessage';
import { PAGES } from './config/pages';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import useOptimizelyListener from './hooks/useOptimizelyListener';
import { useSetupApp } from './hooks/useSetupApp';
import { ChromeHeader } from './modules/ChromeHeader/ChromeHeader';
import { CookieBanner } from './modules/CookieBanner/CookieBanner';
import { getIsCryptoPaymentOutcome } from './modules/Crypto/cryptoUtils';
import { ErrorModal } from './modules/ErrorModal/ErrorModal';
import { getIsGoCardlessInstantBankPaymentOutcome } from './modules/PayWithBank/payWithBank.utils';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { queryCheckoutApi } from './redux/checkoutApiSlice/queryCheckoutApi';
import { updateErrorType } from './redux/session/session.actions';
import { getSessionCheckoutId } from './redux/session/session.selectors';
import filterAndLogAppError from './utils/filterAndLogAppError';
import { getSourcePagePath } from './utils/getSourcePagePath';
import { setupSift, setupAccertify } from './utils/vendorAPIs';

const jgDebug = debug('jg:checkout');

type State = {
  isLoading: boolean;
  isError: boolean;
};

type Action = { type: 'SUCCESS' } | { type: 'REDIRECTING_TO_SSO' } | { type: 'FAILURE' };

type RootProps = { serverData?: Checkout };

const Root: FunctionComponent<RootProps> = ({ serverData }) => {
  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case 'SUCCESS': {
        return {
          isLoading: false,
          isError: false,
        };
      }
      case 'REDIRECTING_TO_SSO': {
        return {
          isLoading: true,
          isError: false,
        };
      }
      case 'FAILURE': {
        return {
          isLoading: false,
          isError: true,
        };
      }
      default:
        return state;
    }
  };

  const { setupApp } = useSetupApp();

  const [status, statusDispatch] = useReducer(reducer, {
    isLoading: true,
    isError: false,
  });

  const checkoutId = useAppSelector(getSessionCheckoutId);

  useEffect(() => {
    if (checkoutId) {
      setupSift(checkoutId);
      setupAccertify(checkoutId);
    }
  }, [checkoutId]);

  const navigate = useNavigate();

  try {
    useOptimizelyListener();
  } catch (error) {
    // Do nothing
  }

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const setup = useCallback(async () => {
    // TODO: Remove this temporary hack after 30/11/2024 (request: ADO 2931675 + ADO 2942269, clean-up: ADO 2932295)
    if (
      window.location.search?.includes(
        'uri=aHR0cHM6Ly9kb25hdGUtYXBpLmp1c3RnaXZpbmcuY29tL2FwaS9kb25hdGlvbnMvYjBmNDE1YWU5OTg2NGYxMGFlYzQxMjYxYjM3YTA1MGE/aXNDaGVja291dEFwaT10cnVl',
      ) ||
      window.location.search?.includes(
        'uri=aHR0cHM6Ly9kb25hdGUtYXBpLmp1c3RnaXZpbmcuY29tL2FwaS9kb25hdGlvbnMvODI4OTIzYjYyNTQzNDU2MmFiMDYzOThjYjA3NWU2ODM/aXNDaGVja291dEFwaT10cnVl',
      )
    ) {
      window.location.href = 'https://checkout.justgiving.com/c/249218';
      return;
    }

    if (!checkoutId) {
      statusDispatch({ type: 'FAILURE' });
      dispatch(updateErrorType('NoCheckoutSessionId'));
      return;
    }

    try {
      if (serverData) {
        await dispatch(queryCheckoutApi.util.upsertQueryData('fetchCheckout', undefined, serverData));
      }

      const { redirectingToSSO, redirectToPage } = await setupApp();
      if (redirectToPage) {
        navigate(redirectToPage, { replace: true });
        return statusDispatch({ type: 'SUCCESS' });
      }
      if (redirectingToSSO) {
        return statusDispatch({ type: 'REDIRECTING_TO_SSO' });
      } else {
        return statusDispatch({ type: 'SUCCESS' });
      }
    } catch (err) {
      if (getIsGoCardlessInstantBankPaymentOutcome() || getIsCryptoPaymentOutcome()) {
        // If GQL errors we still assume that GCIBP/Crypto payment has been successful at this point and render the thankyou page
        const sourcePagePath = getSourcePagePath();
        navigate(`${sourcePagePath}/${PAGES.ThankYouPage}?isGCIBP=true`);
        statusDispatch({ type: 'SUCCESS' });
        return;
      }
      jgDebug(err);

      if (err?.message === httpErrorMessage.UNAUTHORIZED_RECOVERABLE) {
        statusDispatch({ type: 'REDIRECTING_TO_SSO' });
      } else if (err?.message === httpErrorMessage.UNAUTHORIZED_NOT_RECOVERABLE) {
        statusDispatch({ type: 'FAILURE' });
        dispatch(updateErrorType('GuestAuthFailure'));
      } else {
        statusDispatch({ type: 'FAILURE' });
        dispatch(updateErrorType('Unrecoverable'));
      }
      filterAndLogAppError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language ?? '');
  }, [i18n.language]);

  useEffect(() => {
    setup();
  }, [setup]);

  if (status.isLoading) {
    return <LoadingPage />;
  }

  if (status.isError) {
    return (
      <>
        <ChromeHeader skipFetch />
        <ErrorModal />
      </>
    );
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <ErrorBoundary>
        <OverlayLoader>
          <ErrorModal />
          <HandleLogin />
          <HandleRouteChange />
          <CookieBanner />
          <App />
        </OverlayLoader>
      </ErrorBoundary>
    </Suspense>
  );
};

export default Root;
