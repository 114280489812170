import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useTipJarModeConfig } from '../../hooks/useTipJarModeConfig';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLDefaultDonationValue } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLTipJarInitialValues,
  getGQLTipJarValues,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLAppliedTipScheme,
  getGQLTipAmountInPounds,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { initTip } from '../../redux/tip/tip.slice';

export type LabelAndValue = {
  label: string;
  value: number;
};

export const useInitTipjar = () => {
  const dispatch = useAppDispatch();
  const { data } = useFetchCheckoutQuery();
  const { tipJarValuesOverride } = useTipJarModeConfig();
  const transactionType = useWatch<AmountPageFormValues, 'transactionType'>({
    name: 'transactionType',
  });

  const appliedTipScheme = getGQLAppliedTipScheme(data);
  const isTipValueSubmitted = Boolean(appliedTipScheme);
  const tipAmountInPounds = getGQLTipAmountInPounds(data);
  const initialDonationValue = String(getGQLLineItemValueInPounds(data) || getGQLDefaultDonationValue(data) || 0);
  const isCryptoTransactionTypeSelected = transactionType === TransactionTypes.CRYPTO;

  const tipJarValues = getGQLTipJarValues(
    initialDonationValue,
    isCryptoTransactionTypeSelected,
    tipJarValuesOverride,
  )(data);
  const initialValues = getGQLTipJarInitialValues(
    appliedTipScheme ?? null,
    tipAmountInPounds,
    initialDonationValue,
    isCryptoTransactionTypeSelected,
    tipJarValuesOverride,
  )(data);

  const tipJarHasValues = Boolean(tipJarValues && initialValues);

  useEffect(() => {
    if (!tipJarHasValues) return;
    dispatch(
      initTip({
        inputMode: initialValues?.noDropdownValue ? 'Other' : 'Selection',
        selectionValue: initialValues?.dropdownValue,
        userSelectionMade: isTipValueSubmitted || Number(initialValues?.dropdownValue) !== tipJarValues?.defaultValue,
        tipValues: tipJarValues?.values,
        valueType: tipJarValues?.valueType,
        defaultValue: tipJarValues?.defaultValue,
        lastSelectedAmountValue: tipJarValues?.valueType === 'Amount' ? initialValues?.dropdownValue : undefined,
        lastSelectedPercentageValue:
          tipJarValues?.valueType === 'Percentage' ? initialValues?.dropdownValue : undefined,
      }),
    );
  }, [tipJarHasValues]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useInitTipjar;
