import React, { Suspense, lazy, FunctionComponent, useEffect, useState, StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from '@justgiving/auth-react';
import { OptimizelyProvider } from '@optimizely/react-sdk';

import { Checkout } from '../../types/__generated-graphQL__';
import APP_SETTINGS from '../config';
import { initI18n } from '../i18next';
import Root from './';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { store, auth, optimizely } from './redux/configureStore';
import { manageAnalyticsCookie } from './utils/manageAnalyticsCookie';

manageAnalyticsCookie(auth);

const CreateDonationPage = lazy(
  () => import(/* webpackChunkName: "CreateDonation" */ './pages/CreateDonationPage/CreateDonationPage'),
);
const PaymentRedirection = lazy(
  () => import(/* webpackChunkName: "PaymentRedirectionPage" */ './modules/PaymentRedirection/PaymentRedirection'),
);

type ClientProps = {
  serverData?: Checkout;
};

export const Client: FunctionComponent<ClientProps> = ({ serverData }) => {
  const [loadingTranslations, setLoadingTranslations] = useState(true);

  const loadTranslations = async () => {
    await initI18n();
    setLoadingTranslations(false);
  };

  useEffect(() => {
    loadTranslations();
  }, []);

  if (loadingTranslations) return null;

  if (['local', 'stg'].includes(APP_SETTINGS.env) && window.location.pathname === '/create') {
    return (
      <StrictMode>
        <Suspense fallback={<LoadingPage />}>
          <CreateDonationPage />
        </Suspense>
      </StrictMode>
    );
  }

  if (window.location.pathname === '/handle_payment_redirect') {
    return (
      <StrictMode>
        <PaymentRedirection />
      </StrictMode>
    );
  }

  return (
    <StrictMode>
      <Suspense fallback={<LoadingPage />}>
        <BrowserRouter>
          <OptimizelyProvider optimizely={optimizely} user={{ id: null }}>
            <Provider store={store}>
              <AuthProvider auth={auth}>
                <Root serverData={serverData} />
              </AuthProvider>
            </Provider>
          </OptimizelyProvider>
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  );
};
