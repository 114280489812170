import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LineItemType, MarketCode, TipScheme } from '../../../../types/__generated-graphQL__';
import FavoriteIcon from '../../../assets/images/favorite_black.svg';
import FundraisingRegulatorDark from '../../../assets/images/fundraising-regulator-dark.svg';
import FundraisingRegulator from '../../../assets/images/fundraising-regulator.svg';
import GlobeAndHeart from '../../../assets/images/globeAndHeart.svg';
import LockIcon from '../../../assets/images/lock_black.svg';
import { Image } from '../../components/Image/Image';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLCauseTipScheme,
  getGQLMarketCode,
  getGQLOrderType,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import * as styles from './trustMark.css';

const TrustMarkIcons: FunctionComponent = () => {
  const { t } = useTranslation();

  const { orderType } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        orderType: getGQLOrderType(data),
      };
    },
  });
  const { accordionEnabled } = useAccordionExperiment();

  return (
    <ul className={styles.iconWrapper}>
      <li className={styles.icon}>
        <Image src={FavoriteIcon} className={styles.iconImg} width={18} height={16} />
        <span className={accordionEnabled ? styles.accordionIconLabel : styles.iconLabel}>
          {orderType === LineItemType.DonationCrowdfunding
            ? t('trustMark|noFeesForOrganisers')
            : t('trustMark|noFeesForPartners')}
        </span>
      </li>
      <li className={styles.icon}>
        <Image src={GlobeAndHeart} className={styles.iconImg} width={23} height={22} />
        <span className={accordionEnabled ? styles.accordionIconLabel : styles.iconLabel}>
          {orderType === LineItemType.DonationCrowdfunding
            ? t('trustMark|trustedByCrowdfunders')
            : t('trustMark|trustedByCustomers')}
        </span>
      </li>
      <li className={styles.icon}>
        <Image src={LockIcon} className={styles.iconImg} width={18} height={23} />
        <span className={accordionEnabled ? styles.accordionIconLabel : styles.iconLabel}>
          {t('trustMark|100percentSecurePayments')}
        </span>
      </li>
      {/* TODO Check after OCTOBER 4th to see if our trustpilot score has improved or if its time to delete this */}
      {/*<li className={styles.icon}>*/}
      {/*  <Image src={StarIcon} className={styles.iconImg} width={23} height={22} />*/}
      {/*  <span className={accordionEnabled ? styles.accordionIconLabel : styles.iconLabel}>*/}
      {/*    {t('trustMark|excellentOnTrustPilot')}*/}
      {/*  </span>*/}
      {/*</li>*/}
    </ul>
  );
};

const TrustMark: FunctionComponent = () => {
  const { marketCode, causeTipScheme } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        marketCode: getGQLMarketCode(data),
        causeTipScheme: getGQLCauseTipScheme(data),
      };
    },
  });
  const { accordionEnabled } = useAccordionExperiment();

  const { t } = useTranslation();

  if (causeTipScheme === TipScheme.Dpf1_2) {
    return null;
  }

  return (
    <section className={styles.wrapper}>
      <span className={accordionEnabled ? styles.accordionTitle : styles.title}>{t('trustMark|title')}</span>
      <TrustMarkIcons />
      {marketCode === MarketCode.Gb && (
        <>
          {accordionEnabled ? (
            <Image
              className={accordionEnabled ? styles.accordionFundraisingRegulator : styles.fundraisingRegulator}
              src={FundraisingRegulatorDark}
              width="157"
              height="74"
            />
          ) : (
            <Image className={styles.fundraisingRegulator} src={FundraisingRegulator} width="157" height="48" />
          )}

          <VisuallyHidden>{t('trustMark|fundraisingRegulator')}</VisuallyHidden>
        </>
      )}
    </section>
  );
};

export default TrustMark;
