import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGES } from '../../config/pages';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { usePageName } from '../../hooks/usePageName';
import useAccordionExperiment from '../../modules/AccordionPages/useAccordionExperiment';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsTransactionComplete } from '../../redux/checkoutApiSlice/selectors/transaction.selectors';
import { updateHistory } from '../../router/router.slice';
import { getSourcePagePath } from '../../utils/getSourcePagePath';
import { trackSiftSciencePageView } from '../../utils/vendorAPIs';

const HandleRouteChange: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { accordionEnabled } = useAccordionExperiment();

  const pageName = usePageName();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isTransactionComplete = getGQLIsTransactionComplete(data);

  useEffect(() => {
    if (!accordionEnabled) {
      window.scrollTo(0, 0);
    }
    trackSiftSciencePageView();

    pageName && dispatch(updateHistory(pageName));

    if (pageName !== 'ThankYouPage' && isTransactionComplete) {
      const sourcePagePath = getSourcePagePath();
      navigate(`${sourcePagePath}/${PAGES.ThankYouPage}${window.location.search}`);
    }
  }, [pageName, dispatch, isTransactionComplete, navigate, accordionEnabled]);

  return null;
};

export default HandleRouteChange;
