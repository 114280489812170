import { AcceptedCurrencyCode, PaymentType, TipScheme } from './__generated-graphQL__';

export type AddPaymentCardPageFormValues = {
  cardNumber: string;
  nameOnCard: string;
  cardExpiryYear: string;
  cardExpiryMonth: '' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12';
  cvv: string;
  houseNameOrNumber: string;
  zipOrPostalCode: string;
  saveCardForFutureUse: boolean;
  cardExpiryMonthAndYear?: any; // only used for error message
  addressLine1?: string;
  addressLine2?: string;
  townOrCity?: string;
  countryCode?: string;
  countyStateRegion?: string;
};

export type AmexPWPPageFormValues = {
  pointsValue?: string;
};

export type DirectDebitFormValues = {
  accountHolderName: string;
  sortCode: string;
  accountNumber: string;
  dayOfMonth: number;
  cancelWhenCauseExpires?: 'yes' | 'no';
  address?: {
    id?: string;
    line1: string;
    line2?: string;
    country: string;
    townCity: string;
    countyStateRegion: string;
    postalCode: string;
  };
  accountDetails: any; // only used for error message
};

export enum TransactionTypes {
  ONE_OFF = 'ONE_OFF',
  RECURRING = 'RECURRING',
  CRYPTO = 'CRYPTO',
}

export type AmountPageFormValues = {
  donationCurrencyCode: AcceptedCurrencyCode;
  donationValue: string;
  recurrenceDayOfMonth: string | null;
  transactionType: TransactionTypes;
  isDonorPayingFees?: boolean;
  totalAmountToPay: null | number;
  isAmountPrivate: boolean;
  causeTipScheme: TipScheme | null;
  directDebit?: DirectDebitFormValues;
  tipAmount?: number;
  otherTipAmount?: string;
  crypto?: {
    currencyFullName: string;
    currencyLogoUrl: string;
    currencyShortName: string;
    estimatedExchangeRate: string;
    fiatAmount: string;
    cryptoAmount: string;
  };
};

export type OtherTipModalFormValues = {
  otherAmountModalInput: string;
};

export interface SelectPaymentTypeFormValues extends AddPaymentCardPageFormValues {
  paymentType: PaymentType;
}

export type MessagePageFormValues = {
  message: string;
  avatarName: string;
  isAnonymous: boolean;
};

export type SummaryPageFormValues = {
  cvv?: string;
  selectedPaymentCardId?: string | null;
  consents: Record<string, boolean>;
  firstName: string;
  lastName: string;
  emailAddress: string;
  donationValue?: string;
  tipAmount?: number;
  totalAmountToPay?: number | null;
};

export type GiftAidDeclarationFormValues = {
  addGiftAid: boolean;
};

export type GiftAidDetailsFormValues = {
  giftAidFirstName: string;
  giftAidLastName: string;
  giftAidIsOverSeas: boolean | null;
  giftAidAddressLine1: string;
  giftAidAddressLine2: string;
  giftAidTownCity: string;
  giftAidCountyStateRegion: string;
  giftAidCountry: string;
  giftAidPostalCode: string;
  giftAidSelection?: string;
  lookupPostalCode?: string;
};
