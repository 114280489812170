import bankersRounding from 'bankers-rounding';

import { getUrlSearchParameters } from '../../utils/getUrlSearchParameters';

export const calculateCryptoAmount = (fiatAmount: number, exchangeRate: number): string =>
  isNaN(fiatAmount) ? '0' : bankersRounding(fiatAmount / exchangeRate, 8).toString();

export const calculateFiatAmount = (cryptoAmount: string, exchangeRate: number): string =>
  isNaN(Number(cryptoAmount)) ? '0' : bankersRounding(Number(cryptoAmount) * exchangeRate, 2).toString();

export const convertScientificNotationToLongForm = (value: string): string => {
  if (!value.includes('-', 1)) return value;
  return Number(value).toFixed(Number(value.split('-')[1]));
};

export const getIsCryptoPaymentOutcome = () => {
  const urlSearchParameters = getUrlSearchParameters();
  const isGCIBP = new URLSearchParams(urlSearchParameters).get('isCryptoSuccess');
  return Boolean(isGCIBP);
};
