import { combineReducers } from 'redux';

import * as abTestReducers from '../modules/ABTests/abTests.reducers';
import * as amexPWPReducers from '../modules/AmexPayWithPoints/AmexPayWithPoints.reducer';
import router from '../router/router.slice';
import { additionalPageDataApiSlice } from './additionalPageDataApiSlice/additionalPageDataApiSlice';
import { addressLookupApiSlice } from './addressLookupApiSlice/addressLookupApiSlice';
import auth from './auth/auth.reducers';
import { checkoutApiSlice } from './checkoutApiSlice/checkoutApiSlice';
import guest from './guest/guest.reducer';
import { preferencesApiSlice } from './preferencesApiSlice/preferencesApiSlice';
import session from './session/session.reducers';
import tip from './tip/tip.slice';
import { transactionStatusApiSlice } from './transactionStatusApiSlice/transactionStatusApiSlice';

export default combineReducers({
  ...abTestReducers,
  ...amexPWPReducers,
  auth,
  tip,
  session,
  guest,
  router,
  [checkoutApiSlice.reducerPath]: checkoutApiSlice.reducer,
  [additionalPageDataApiSlice.reducerPath]: additionalPageDataApiSlice.reducer,
  [addressLookupApiSlice.reducerPath]: addressLookupApiSlice.reducer,
  [transactionStatusApiSlice.reducerPath]: transactionStatusApiSlice.reducer,
  [preferencesApiSlice.reducerPath]: preferencesApiSlice.reducer,
});
