import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import APP_SETTINGS from '../../../config';
import Alert from '../../components/alert/Alert';
import Modal from '../../components/modal/Modal';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getErrorType } from '../../redux/session/session.selectors';
import { getSourcePagePath } from '../../utils/getSourcePagePath';

export const ErrorModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const sessionError = useAppSelector(getErrorType);

  if (!sessionError) return null;

  return (
    <Modal id="modal-redirection" isOpen={true} contentLabel={t('modalContentLabel', { ns: 'alert' })}>
      <Alert alertType="red" showSideBorders dontSnackbar>
        <ModalContent errorType={sessionError} />
      </Alert>
    </Modal>
  );
};

type ModalContentProps = {
  errorType: ErrorType;
};

const ModalContent: FunctionComponent<ModalContentProps> = ({ errorType }) => {
  const { t } = useTranslation();
  const sourcePagePath = getSourcePagePath();

  if (!sourcePagePath) {
    return <>{t('invalidDonation', { ns: 'alert' })}</>;
  }

  const redirectionUrl = getRedirectionUrl(sourcePagePath);

  switch (errorType) {
    case 'GuestAuthFailure':
      return (
        <Trans i18nKey="guestAuthFailure" ns="alert">
          Your session has timed out.
          <Link to={redirectionUrl}>Please restart your donation</Link>
        </Trans>
      );
    case 'NoCheckoutSessionId':
      return (
        <Trans i18nKey="noCheckoutSessionId" ns="alert">
          Sorry, this donation is not valid.
          <Link to={redirectionUrl}>Please restart your donation</Link>
        </Trans>
      );
    case 'Unrecoverable':
      return <>{t('invalidDonation', { ns: 'alert' })}</>;
  }
};

const getRedirectionUrl = (sourcePagePath: string): string => {
  if (sourcePagePath.startsWith('/charity/')) {
    return `${APP_SETTINGS.HOME_URL}${sourcePagePath?.substring(8)}`;
  }

  if (sourcePagePath.startsWith('/crowdfunding/')) {
    return `${APP_SETTINGS.HOME_URL}${sourcePagePath}/donate?startedBy=cfwebsite`;
  }

  return `${APP_SETTINGS.HOME_URL}${sourcePagePath}/donate?startedBy=webjustgiving`;
};
