import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { CardType } from '../../../../types/__generated-graphQL__';
import { sprinkles } from '../../../styles/sprinkles.css';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLCurrencyCode,
  getGQLIsAmexPayWithPointsAvailable,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLPaymentCardDetailsCardType } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { formatCurrency, formatPoints } from '../../utils/value';
import { getAmexPWPAppliedPoints } from './AmexPayWithPoints.selectors';

const AmexPWPThankYou: FunctionComponent = () => {
  const { data } = useFetchCheckoutQuery();
  const { t } = useTranslation();
  const isAmexPayWithPointsAvailable = getGQLIsAmexPayWithPointsAvailable(data);
  const cardType = getGQLPaymentCardDetailsCardType(data);
  const appliedPoints = useAppSelector(getAmexPWPAppliedPoints);
  const currencyCode = getGQLCurrencyCode(data);

  if (!isAmexPayWithPointsAvailable) return null;
  if (cardType !== CardType.AmericanExpress) return null;
  if (!appliedPoints) return null;
  if ((appliedPoints?.pointCurrencyValue ?? 0) < 1) return null;

  return (
    <>
      <p className={sprinkles({ marginTop: 'sizeSpacing06' })}>{t('thankYou|title', { ns: 'amexPwp' })}</p>
      <p data-qa="donation-amex-reward-points">
        <strong>
          {formatCurrency(currencyCode, appliedPoints.pointCurrencyValue)}
          {' ('}
          {t('points', { ns: 'amexPwp', points: formatPoints(appliedPoints.appliedPoints) })}
          {')'}
        </strong>
      </p>
    </>
  );
};

export default AmexPWPThankYou;
