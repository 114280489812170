import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import classNames from 'classnames';

import { useFetchPageQuery } from '../../redux/additionalPageDataApiSlice/additionalPageDataApiSlice';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLPage } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { calculatePercantageRaised } from '../../utils/calculatePercantageRaised';
import { convertPenniesToPounds } from '../../utils/utilityMapperFunctions';
import { formatCurrency } from '../../utils/value';
import * as styles from './DonationTargetBar.css';

const getTargetType = (targetValue: number, raisedValue?: number) => {
  if (!raisedValue) return 'first';
  if (raisedValue >= targetValue) return 'matched';
  return 'open';
};

const DonationTargetBar: FunctionComponent = () => {
  const { targetCurrency, targetValue } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        targetCurrency: getGQLPage(data)?.targetWithCurrency?.currencyCode,
        targetValue: getGQLPage(data)?.targetWithCurrency?.value,
      };
    },
  });
  const { raisedValue, isFetching, isSuccess } = useFetchPageQuery(undefined, {
    selectFromResult: ({ data, isFetching, isSuccess }) => {
      return {
        isFetching,
        isSuccess,
        raisedValue: data?.donationSummary?.totalAmount?.value,
      };
    },
  });
  const { t } = useTranslation();

  if (!targetValue) return null;

  const percentageRaisedOfTarget = calculatePercantageRaised(targetValue, raisedValue);

  return (
    <div className={styles.wrapper} data-qa="DonationTargetBar">
      <div
        className={styles.barContainer}
        style={
          isSuccess
            ? assignInlineVars({
                [styles.percentageToTarget]: `translateX(${percentageRaisedOfTarget}%)`,
              })
            : undefined
        }
      >
        <div className={classNames(styles.bar, { [styles.startAnimation]: isSuccess })}></div>
      </div>
      <div className={styles.targetText}>
        {isFetching ? (
          <>&nbsp;</>
        ) : (
          <>
            {t(`target|${getTargetType(targetValue, raisedValue)}`, {
              target: formatCurrency(targetCurrency, convertPenniesToPounds(targetValue), false),
              raised: formatCurrency(targetCurrency, convertPenniesToPounds(raisedValue ?? 0), false),
              ns: 'page',
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default DonationTargetBar;
