import { useCallback, useContext } from 'react';

import { AuthContext } from '@justgiving/auth-react';

import { useAppSelector } from '../../hooks/reduxHooks';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCheckoutMode } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLCharityName,
  getGQLIsDonorPayingFees,
  getGQLLegacyCharityId,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import {
  getGQLIsDonorPaysFeesEnabled,
  getGQLLineItemValueInPounds,
  getGQLMarketCode,
  getGQLOrderType,
  getGQLisTipJarEnabled,
} from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLCurrencyCode,
  getGQLAppliedTipScheme,
  getGQLTipAmountInPounds,
} from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLCheckoutId } from '../../redux/checkoutApiSlice/selectors/root.selectors';
import { logError } from '../../utils/logError';
import { getQualarooCauseTypeValue, getQualarooIdentifyValue } from '../../utils/qualaroo';
import { mapTipSchemeToLinkServiceFormat } from '../../utils/utilityMapperFunctions';
import { getEnrolledInOptimizelyForQualaroo } from '../ABTests/abTests.selectors';

type QualarooAnswer = {
  question: string;
  answer: string;
};

export default function useScripts() {
  const { data } = useFetchCheckoutQuery();
  const auth = useContext(AuthContext);
  const checkoutId = getGQLCheckoutId(data);
  const isGuest = auth?.isGuest() ?? false;
  const orderType = getGQLOrderType(data);
  const isTipJarEnabled = getGQLisTipJarEnabled(data);
  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const isDonorPayingFees = getGQLIsDonorPayingFees(data);
  const currencyCode = getGQLCurrencyCode(data);
  const tipScheme = getGQLAppliedTipScheme(data);
  const tipAmountInPounds = getGQLTipAmountInPounds(data);
  const isDonorPaysFeesEnabled = getGQLIsDonorPaysFeesEnabled(data);
  const marketCode = getGQLMarketCode(data);
  const charityName = getGQLCharityName(data);
  const legacyCharityId = getGQLLegacyCharityId(data) ?? '';
  const abTestsEnrollmentsOptimizelyForQualaroo = useAppSelector(getEnrolledInOptimizelyForQualaroo);
  const checkoutMode = getGQLCheckoutMode(data);

  const qualaroo = useCallback(() => {
    if (!window.Cookiebot?.consent?.marketing) {
      return;
    }

    try {
      window._kiq = window._kiq || [];
      window._kiq.push(['identify', getQualarooIdentifyValue(isGuest)]);
      window._kiq.push([
        'set',
        {
          donationCollectionId: checkoutId,
          causeType: getQualarooCauseTypeValue(orderType),
          charityName: charityName ?? '',
          charityId: legacyCharityId,
          isTipJarEnabled,
          donationCurrency: currencyCode,
          userTipped: tipAmountInPounds > 0,
          percentageTipped:
            !tipAmountInPounds || !lineItemValueInPounds ? 0 : (tipAmountInPounds / lineItemValueInPounds) * 100,
          donationAmount: lineItemValueInPounds,
          isDPFEnabled: isDonorPaysFeesEnabled,
          userLeftDPFTicked: isDonorPayingFees,
          tipScheme: mapTipSchemeToLinkServiceFormat(tipScheme),
          marketCode,
          checkoutMode: checkoutMode ?? '',
          ...abTestsEnrollmentsOptimizelyForQualaroo,
        },
      ]);
      try {
        window._kiq.push([
          'eventHandler',
          'submit',
          (answers_array: QualarooAnswer[], nudge_id: string, question_id: string) => {
            const surveryResponses: Record<string, number | string> = {
              qual_nudge_id: nudge_id,
              qual_question_id: question_id,
            };
            answers_array.map((response, index) => {
              if (index > 3) return null;
              if (response.answer && response.question) {
                const modifiedQuestion = response.question.replace(/\W/gm, '');

                surveryResponses[`qual_${modifiedQuestion.substring(0, 20)}`] = response.answer.substring(0, 20);
              }
              return null;
            });

            window.heap?.track?.('qualarooSurveyCompletion', surveryResponses);
          },
        ]);
      } catch (error) {
        // Do nothing
      }
      const d = document;
      const f = d.getElementsByTagName('script')[0];
      const s = d.createElement('script');

      s.type = 'text/javascript';
      s.async = true;
      s.src = '//cl.qualaroo.com/ki.js/63657/eF3.js';
      (f as any).parentNode.insertBefore(s, f);
    } catch (error) {
      logError({
        tags: ['qualaroo', 'error'],
        message: error.message,
        stacktrace: error.stack,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderType,
    charityName,
    legacyCharityId,
    currencyCode,
    lineItemValueInPounds,
    checkoutId,
    isDonorPaysFeesEnabled,
    isDonorPayingFees,
    isGuest,
    isTipJarEnabled,
    tipScheme,
    tipAmountInPounds,
    marketCode,
    checkoutMode,
  ]);

  return {
    qualaroo,
  };
}
