import React, { FunctionComponent } from 'react';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { AmountPageFormValues } from '../../../../types/formValues';
import tipIllustration from '../../../assets/images/Tip-Illustrations.png';
import { sprinkles } from '../../../styles/sprinkles.css';
import { Image } from '../../components/Image/Image';
import Button from '../../components/button/Button';
import Modal from '../../components/modal/Modal';
import { useHeadingSetFocusRef } from '../../hooks/useHeadingSetFocusRef';
import { OnSumbitOptions } from '../../pages/AmountPage/AmountPage';
import { formatCurrency } from '../../utils/value';
import * as styles from './AmountBoostModal.css';

type AmountBoostModalProps = {
  showModal: boolean;
  onSubmit: (options?: OnSumbitOptions) => SubmitHandler<any>;
};

export const AmountBoostModal: FunctionComponent<AmountBoostModalProps> = ({ onSubmit, showModal }) => {
  const { t } = useTranslation();

  const headingRef = useHeadingSetFocusRef();
  const [donationValue, donationCurrencyCode] = useWatch<AmountPageFormValues>({
    name: ['donationValue', 'donationCurrencyCode'],
  });

  const amountToBoost = 2;

  const {
    setValue,
    formState: { isSubmitting },
  } = useFormContext<AmountPageFormValues>();

  const adjustAmount = async (event: React.BaseSyntheticEvent) => {
    const newDonationValue = Number(donationValue ?? '0') + amountToBoost;
    setValue('donationValue', String(newDonationValue));
    // TODO Hack to allow tipjar to work out its amounts correctly before submission, if the test is successful we will have to look if theres a better way to do this
    await new Promise(r => setTimeout(r, 100));
    onSubmit()(event);
  };

  if (!showModal) return null;

  return (
    <Modal
      id="modal-share"
      isOpen={true}
      contentLabel={t('amountBoostModal|title', {
        ns: 'ab',
        amount: formatCurrency(donationCurrencyCode, amountToBoost, false),
      })}
    >
      <div className={styles.header}>
        <Image src={tipIllustration} width="280" height="120" className={sprinkles({ marginTop: 'sizeSpacing04' })} />
        <h2 ref={headingRef} tabIndex={-1} className={styles.heading}>
          {t('amountBoostModal|title', {
            ns: 'ab',
          })}
        </h2>

        <p className={sprinkles({ marginTop: 'sizeSpacing06' })}>
          {/* Localisation of £3.9 million is tricky, look into this if the test is successful*/}
          <Trans i18nKey="amountBoostModal|para1" ns="ab">
            If every donor who gave £10 or more last year increased their donation by just £2, charities could have
            raised an additional <span className={sprinkles({ fontWeight: '600' })}>£3.9 Million.</span>
          </Trans>
        </p>

        <p className={sprinkles({ marginTop: 'sizeSpacing06' })}>
          {t('amountBoostModal|para2', {
            ns: 'ab',
          })}
        </p>

        <Button
          className={sprinkles({ marginTop: 'sizeSpacing06' })}
          isLoading={isSubmitting}
          data-qa="vc-modal-continue"
          type="button"
          onClick={adjustAmount}
        >
          {t('amountBoostModal|button|boost', {
            ns: 'ab',
            amount: formatCurrency(donationCurrencyCode, amountToBoost, false),
          })}
        </Button>

        <Button
          className={sprinkles({ marginTop: 'sizeSpacing04' })}
          isLoading={isSubmitting}
          data-qa="vc-modal-continue"
          color="ghost"
          type="button"
          onClick={onSubmit()}
        >
          {t('amountBoostModal|button|keep', { ns: 'ab' })}
        </Button>
      </div>
    </Modal>
  );
};
