import React, { FunctionComponent } from 'react';

import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCheckoutMode } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLIsBBGF } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import BBGFTerms from '../BBGF/BBGFTerms';
import * as styles from './AmountPageFooter.css';
import { SecurelyProvidedBy } from './SecurelyProvidedBy';

const AmountPageFooter: FunctionComponent = () => {
  const { checkoutMode, isBBGF } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        checkoutMode: getGQLCheckoutMode(data),
        isBBGF: getGQLIsBBGF(data),
      };
    },
  });
  const isLowBranded = checkoutMode === 'LowBranded';

  if (!isLowBranded && !isBBGF) {
    return null;
  }

  return (
    <footer className={styles.wrapper}>
      {isLowBranded && <SecurelyProvidedBy />}
      <BBGFTerms />
    </footer>
  );
};

export default AmountPageFooter;
