export const filterGqlInputForLogging = (variables?: Record<string, any>) => {
  if (!variables) return {};
  const allowedKeys = [
    'input',
    'checkoutId',
    'slug',
    'type',
    'currency',
    'isAmountPrivate',
    'isRecurring',
    'recurrenceDayOfMonth',
    'tipScheme',
    'tipValue',
    'value',
    'directDebit',
    'dayOfMonth',
    'crypto',
    'currencyFullName',
    'currencyLogoUrl',
    'estimatedExchangeRate',
    'cancelWhenCauseExpires',
    'selectedPaymentType',
    'message',
    'isAnonymous',
    'paymentType',
    'points',
    'isClaimed',
  ];

  return Object.keys(variables).reduce((acc: Record<string, any>, k) => {
    if (allowedKeys.includes(k)) {
      if (typeof variables[k] === 'object' && variables[k] !== null) {
        acc[k] = filterGqlInputForLogging(variables[k]);
      } else {
        acc[k] = variables[k];
      }
    } else {
      acc[k] = 'REDACTED';
    }
    return acc;
  }, {});
};
