import { gql } from 'graphql-tag';

import { Checkout, UpdateCheckoutOrderAmountInput } from '../../../../types/__generated-graphQL__';
import { checkoutDetails } from '../../graphQL/fragmentCheckoutDetails';
import { checkoutApiSlice } from './checkoutApiSlice';
import { queryCheckoutApi } from './queryCheckoutApi';

export const mutateAmount = checkoutApiSlice.injectEndpoints({
  endpoints: builder => ({
    mutateAmount: builder.mutation<Checkout, Omit<UpdateCheckoutOrderAmountInput, 'checkoutId'>>({
      query(input) {
        return {
          variables: {
            input,
          },
          document: gql`
            ${checkoutDetails}
            mutation updateCheckoutOrderAmount($input: UpdateCheckoutOrderAmountInput!) {
              updateCheckoutOrderAmount(input: $input) {
                ...checkoutDetails
              }
            }
          `,
        };
      },
      transformResponse: (response: { updateCheckoutOrderAmount: Checkout }) => response.updateCheckoutOrderAmount,
      async onQueryStarted(_input, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(queryCheckoutApi.util.updateQueryData('fetchCheckout', undefined, () => data));
        } catch (err) {
          // Do nothing
        }
      },
    }),
  }),
});

export const { useMutateAmountMutation } = mutateAmount;
