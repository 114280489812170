import { useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Checkout, LineItemType } from '../../../types/__generated-graphQL__';
import { PAGES, PageName } from '../config/pages';
import { useAppSelector } from '../hooks/reduxHooks';
import { useAuth } from '../hooks/useAuth';
import { usePageName } from '../hooks/usePageName';
import { useFetchCheckoutQuery, useLazyFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLSkipMessage } from '../redux/checkoutApiSlice/selectors/additionalData.selectors';
import {
  getGQLDisplayGiftAid,
  getGQLShouldRouteToPWPPage,
  getGQLShouldSkipGiftAidDetails,
} from '../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLSkipGiftAid } from '../redux/checkoutApiSlice/selectors/customAttributes.selectors';
import {
  getGQLGiftAidAddressSelectionHash,
  getGQLGiftAidIsClaimed,
} from '../redux/checkoutApiSlice/selectors/giftAid.selectors';
import { getGQLOrderType, getGQLSourcePageRoute } from '../redux/checkoutApiSlice/selectors/lineItem.selectors';
import {
  getGQLIsDirectDebitSelected,
  getGQLSelectedPaymentType,
} from '../redux/checkoutApiSlice/selectors/payment.selectors';
import { getGQLIsTransactionComplete } from '../redux/checkoutApiSlice/selectors/transaction.selectors';
import { getSummaryPageReady } from '../redux/session/session.selectors';

export const getNextRoute = (currentPageName: PageName | null, isSummaryPageReady: boolean, data?: Checkout) => {
  const isTransactionComplete = getGQLIsTransactionComplete(data);
  const skipMessage = getGQLSkipMessage(data);
  const isDirectDebitSelected = getGQLIsDirectDebitSelected(data);
  const selectedPaymentType = getGQLSelectedPaymentType(data);
  const hasSelectedPaymentType = Boolean(selectedPaymentType);
  const giftAidAddressSelectionHash = getGQLGiftAidAddressSelectionHash(data);
  const isGiftAidClaimed = getGQLGiftAidIsClaimed(data);
  const skipGiftAid = getGQLSkipGiftAid(data);
  const isGiftAidSupported = !skipGiftAid && getGQLDisplayGiftAid(data);
  const shouldRouteToPWPPage = getGQLShouldRouteToPWPPage(data);
  const isSkipMessagePage =
    skipMessage || getGQLOrderType(data) === LineItemType.DonationCharityDirect || isDirectDebitSelected;

  const shouldSkipGiftAidDetails = getGQLShouldSkipGiftAidDetails(data);
  const shouldRouteToGiftAid = isGiftAidSupported && isGiftAidClaimed === null;

  if (isTransactionComplete) {
    return 'ThankYouPage';
  }

  switch (currentPageName) {
    case 'AmountPage': {
      if (isSummaryPageReady) {
        if (shouldRouteToGiftAid) return 'GiftAidDeclarationPage';
        return 'SummaryPage';
      }

      if (!isSkipMessagePage) return 'MessagePage';

      if (shouldRouteToPWPPage) return 'AmexPayWithPointsYourPaymentPage';

      if (!hasSelectedPaymentType) return 'SelectPaymentPage';

      if (shouldRouteToGiftAid) return 'GiftAidDeclarationPage';

      return 'SummaryPage';
    }

    case 'MessagePage': {
      if (isSummaryPageReady) return 'SummaryPage';

      if (shouldRouteToPWPPage) return 'AmexPayWithPointsYourPaymentPage';

      if (!hasSelectedPaymentType) return 'SelectPaymentPage';

      if (shouldRouteToGiftAid) return 'GiftAidDeclarationPage';

      return 'SummaryPage';
    }

    case 'SelectPaymentPage': {
      if (shouldRouteToPWPPage) return 'AmexPayWithPointsYourPaymentPage';

      if (shouldRouteToGiftAid) return 'GiftAidDeclarationPage';

      if (isGiftAidClaimed === true && !giftAidAddressSelectionHash && !shouldSkipGiftAidDetails) {
        return 'GiftAidDetailsPage';
      }

      if (isSummaryPageReady) return 'SummaryPage';

      return 'SummaryPage';
    }

    case 'AmexPayWithPointsYourPaymentPage': {
      if (shouldRouteToGiftAid) return 'GiftAidDeclarationPage';

      if (isSummaryPageReady) return 'SummaryPage';

      return 'SummaryPage';
    }

    case 'GiftAidDeclarationPage': {
      if (isSummaryPageReady) return 'SummaryPage';

      return 'SummaryPage';
    }

    case 'GiftAidDetailsPage': {
      if (isSummaryPageReady) return 'SummaryPage';

      return 'SummaryPage';
    }

    case 'SummaryPage': {
      return 'ThankYouPage';
    }

    default: {
      return currentPageName;
    }
  }
};

export type RouteNextArgs = {
  currentRoute: PageName | null;
  nextRoute: PageName | null;
};

type RouteNextOptions = {
  beforeNavigation?: (args: RouteNextArgs) => Promise<void>;
};

export const useRoutes = (): { routeNext: (options?: RouteNextOptions) => Promise<void> } => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const pageName = usePageName();
  const { manageCreatedDonationLogin } = useAuth();
  const queryString = searchParams.toString();

  const { data } = useFetchCheckoutQuery();
  const sourcePageRoute = getGQLSourcePageRoute(data);
  const [queryCheckout] = useLazyFetchCheckoutQuery();
  const isSummaryPageReady = useAppSelector(getSummaryPageReady);

  const routeNext = useCallback(
    async (options?: RouteNextOptions) => {
      const redirectingToSSO = await manageCreatedDonationLogin();
      const { data } = await queryCheckout(undefined, true);
      const nextRoute = getNextRoute(pageName, isSummaryPageReady, data);
      if (!redirectingToSSO) {
        await options?.beforeNavigation?.({ currentRoute: pageName, nextRoute });
        if (nextRoute) {
          navigate(`${sourcePageRoute}/${PAGES[nextRoute]}?${queryString}`);
        }
      }
    },
    [manageCreatedDonationLogin, pageName, navigate, sourcePageRoute, queryString, queryCheckout, isSummaryPageReady],
  );
  return { routeNext };
};
