import React, { FunctionComponent, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDecision } from '@optimizely/react-sdk';
import classNames from 'classnames';

import { PaymentType } from '../../../../types/__generated-graphQL__';
import { OtherTipModalFormValues } from '../../../../types/formValues';
import tipIllustration from '../../../assets/images/Tip-Illustrations.png';
import { sprinkles } from '../../../styles/sprinkles.css';
import Input from '../../components/Form/Input/Input';
import { InputPrefixOrSuffix } from '../../components/Form/Input/InputPrefixOrSuffix';
import { ErrorWrapper, FormError } from '../../components/FormError/FormError';
import { Image } from '../../components/Image/Image';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import Button from '../../components/button/Button';
import Modal from '../../components/modal/Modal';
import { getAbTestKey } from '../../config/abTests';
import { CONSTANTS } from '../../config/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useHeadingSetFocusRef } from '../../hooks/useHeadingSetFocusRef';
import { useMutateTipMutation } from '../../redux/checkoutApiSlice/mutateTip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLLineItemValueInPounds } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getGQLCurrencyCode } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import {
  getGQLCryptoDetails,
  getGQLSelectedPaymentType,
} from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { getTipOtherValue } from '../../redux/tip/tip.selectors';
import { closeTipModal, setTipInputMode, setTipOtherValue } from '../../redux/tip/tip.slice';
import { formatCurrencySymbol } from '../../utils/currency';
import { formatCurrency } from '../../utils/value';
import { calculateCryptoAmount, convertScientificNotationToLongForm } from '../Crypto/cryptoUtils';
import * as styles from './TipJarOtherAmountModal.css';
import useSetTipAmount from './useSetTipAmount';

interface FormElements extends HTMLFormControlsCollection {
  otherAmountModalInput: HTMLInputElement;
}
interface ModalFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TipJarOtherAmountModal: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const { data } = useFetchCheckoutQuery();
  const [mutateTip] = useMutateTipMutation();

  const [submitFailed, setSubmitFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const lineItemValueInPounds = getGQLLineItemValueInPounds(data);
  const donationCurrencyCode = getGQLCurrencyCode(data);
  const selectedPaymentType = getGQLSelectedPaymentType(data);
  const cryptoDetails = getGQLCryptoDetails(data);
  const isCrypto = selectedPaymentType === PaymentType.Crypto;
  const { setTipAmount } = useSetTipAmount();

  const [{ enabled: tipjarBigOther }] = useDecision(
    getAbTestKey({ abTest: 'TIPJAR_OTHER_BIG', shouldEnrol: lineItemValueInPounds >= 100 }),
  );

  const defaultOtherAmount = tipjarBigOther ? '2.00' : '1.50';
  const defaultOtherPercentage = '1';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const headingRef = useHeadingSetFocusRef();

  const tipOtherValue = useAppSelector(getTipOtherValue);

  const formMethods = useForm<OtherTipModalFormValues>({
    mode: 'onTouched',
    shouldUnregister: true,
  });

  const {
    formState: { isValid },
    control,
    trigger,
  } = formMethods;

  const otherAmountModalInput = useWatch<OtherTipModalFormValues, 'otherAmountModalInput'>({
    control,
    name: 'otherAmountModalInput',
    defaultValue: tipOtherValue ?? defaultOtherPercentage,
  });
  const tipAmountInPounds = lineItemValueInPounds * (Number(otherAmountModalInput) / 100);

  const onSubmit = async (e: React.FormEvent<ModalFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsSubmitting(true);
      const inputValue = e.currentTarget.elements.otherAmountModalInput.value;
      const isValid = await trigger('otherAmountModalInput');
      if (!isValid) return;

      const tipValueString = isCrypto ? tipAmountInPounds.toString() : inputValue;

      const response = await mutateTip({ tipValue: tipValueString || '0' });
      if ('error' in response) {
        throw response.error;
      }

      dispatch(setTipOtherValue({ value: inputValue }));
      dispatch(setTipInputMode({ inputMode: 'Other' }));
      setTipAmount({ valueType: 'Amount', value: Number(tipValueString) });

      dispatch(closeTipModal());
    } catch (error) {
      setSubmitFailed(true);
      setIsSubmitting(false);
    }
  };

  const showHighTipWarning = isCrypto
    ? tipAmountInPounds > lineItemValueInPounds
    : Boolean(Number(otherAmountModalInput) > lineItemValueInPounds);

  return (
    <>
      <Modal
        id="tipJarOtherValueModal"
        isOpen={isOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={false}
        contentLabel={t('modal|heading', { ns: 'tipJar' })}
      >
        <FormProvider {...formMethods}>
          <form method="post" onSubmit={onSubmit} noValidate>
            <div className={styles.header}>
              <Image src={tipIllustration} width="280" height="120" />
              <h2 ref={headingRef} tabIndex={-1} className={styles.heading}>
                {t('modal|heading', { ns: 'tipJar' })}
              </h2>
            </div>
            <ul className={styles.infoList}>
              <li className={`${styles.infoListItem} ${styles.noPlatformFees}`}>
                <span className={styles.infoListHeading}>{t('modal|feeHeading', { ns: 'tipJar' })}</span>
                {t('modal|feeCopy', { ns: 'tipJar' })}
              </li>
              <li className={`${styles.infoListItem} ${styles.moreMoneyForCharities}`}>
                <span className={styles.infoListHeading}>{t('modal|moreMoneyHeading', { ns: 'tipJar' })}</span>
                {t('modal|moreMoneyCopy', { ns: 'tipJar' })}
              </li>
              <li className={`${styles.infoListItem} ${styles.charitiesDeserveBest}`}>
                <span className={styles.infoListHeading}>{t('modal|deserveBestHeading', { ns: 'tipJar' })}</span>
                {t('modal|deserveBestCopy', { ns: 'tipJar' })}
              </li>
            </ul>
            <div className={styles.inputWrapper}>
              <VisuallyHidden as="label" htmlFor="otherAmountModalInput">
                {t('inputLabel', { ns: 'tipJar' })}
              </VisuallyHidden>
              {isCrypto ? (
                <div className={styles.percentageWrapper}>
                  <InputPrefixOrSuffix type="suffix" value="%" wrapperClassName={styles.percentageInput}>
                    <Input
                      id="otherAmountModalInput"
                      name="otherAmountModalInput"
                      className={classNames({ 'is-error': showHighTipWarning })}
                      inputMode="decimal"
                      pattern="[0-9]*"
                      placeholder="0.00"
                      autoComplete="off"
                      data-qa="otherAmountModalInput"
                      defaultValue={tipOtherValue ?? defaultOtherPercentage}
                      registerMethods={{
                        min: { value: 0, message: t('amount|vc-other-amount-crypto|invalid', { ns: 'validation' }) },
                        pattern: {
                          value: CONSTANTS.CURRENCY_REGEXP,
                          message: t('amount|vc-other-amount-crypto|invalid', { ns: 'validation' }),
                        },
                      }}
                    />
                  </InputPrefixOrSuffix>
                  <span className={styles.cryptoAmounts}>
                    {isValid && (
                      <>
                        {convertScientificNotationToLongForm(
                          calculateCryptoAmount(tipAmountInPounds, cryptoDetails?.estimatedExchangeRate ?? 0),
                        )}{' '}
                        {cryptoDetails?.currencyShortName} (
                        {formatCurrency(donationCurrencyCode, tipAmountInPounds, true)})
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <InputPrefixOrSuffix type="prefix" value={formatCurrencySymbol(donationCurrencyCode!)}>
                  <Input
                    id="otherAmountModalInput"
                    name="otherAmountModalInput"
                    className={classNames({ 'is-error': showHighTipWarning })}
                    inputMode="decimal"
                    pattern="[0-9]*"
                    placeholder="0.00"
                    autoComplete="off"
                    data-qa="otherAmountModalInput"
                    defaultValue={tipOtherValue ?? defaultOtherAmount}
                    registerMethods={{
                      min: { value: 0, message: t('amount|vc-other-amount|invalid', { ns: 'validation' }) },
                      pattern: {
                        value: CONSTANTS.CURRENCY_REGEXP,
                        message: t('amount|vc-other-amount|invalid', { ns: 'validation' }),
                      },
                    }}
                  />
                </InputPrefixOrSuffix>
              )}
            </div>
            <FormError<OtherTipModalFormValues> fieldName="otherAmountModalInput" />
            {showHighTipWarning && <ErrorWrapper withIcon>{t('otherHighTipWarning', { ns: 'tipJar' })}</ErrorWrapper>}
            {submitFailed && <ErrorWrapper>{t('recoverable', { ns: 'alert' })}</ErrorWrapper>}
            <p className={styles.inputSubText}>
              {isCrypto
                ? t('modal|inputSubTextPercentage', { ns: 'tipJar', percentage: defaultOtherPercentage })
                : t('modal|inputSubTextAmount', {
                    amount: formatCurrency(donationCurrencyCode, defaultOtherAmount),
                    ns: 'tipJar',
                  })}
            </p>
            <Button
              className={sprinkles({ marginTop: 'sizeSpacing06' })}
              disabled={isSubmitting || !isValid}
              isLoading={isSubmitting}
              data-qa="vc-modal-continue"
            >
              {t('continueLabel')}
            </Button>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default TipJarOtherAmountModal;
