import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/reduxHooks';
import { getIsGlobalLoading } from '../../redux/session/session.selectors';
import Loading from '../Loading/Loading';
import * as styles from './overlayLoader.css';

type OverlayLoaderProps = {
  isLoading?: boolean;
  loadingMessage?: string;
  children?: ReactNode;
};

export const OverlayLoader: FunctionComponent<OverlayLoaderProps> = ({ children, isLoading, loadingMessage }) => {
  const { t } = useTranslation();
  const isGlobalLoading = useAppSelector(getIsGlobalLoading);
  const isLoadingCheck = isLoading ?? isGlobalLoading ?? false;

  return (
    <div className={styles.panel}>
      {isLoadingCheck && (
        <>
          <div className={styles.background}></div>
          <div className={styles.loadingWrapper}>
            <Loading className={styles.loading}>{loadingMessage ?? t('loading')}</Loading>
          </div>
        </>
      )}
      {children}
    </div>
  );
};
