import { useEffect, useMemo } from 'react';

import { useDecision } from '@optimizely/react-sdk';

import { getAbTestKey } from '../../config/abTests';

// Hook to make it easier to manage accordion as a test and a feature
const useAccordionExperiment = () => {
  const [{ enabled: accordionEnabled }] = useDecision(
    getAbTestKey({
      abTest: 'ACCORDION',
    }),
  );

  const isAccordionEnabled = useMemo(() => accordionEnabled, [accordionEnabled]);

  useEffect(() => {
    if (!isAccordionEnabled) return;
    document.documentElement.classList.add('accordionActive');
  }, [isAccordionEnabled]);

  return { accordionEnabled: isAccordionEnabled };
};

export default useAccordionExperiment;
