import React, { ReactNode, SyntheticEvent } from 'react';
import { FunctionComponent } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AmountPageFormValues, SummaryPageFormValues } from '../../../../types/formValues';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useMutateTipMutation } from '../../redux/checkoutApiSlice/mutateTip';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLTotalAmountInPounds } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { endGlobalLoading, startGlobalLoading } from '../../redux/session/session.actions';

type SummaryEditLinkProps = {
  to: string;
  dataQa?: string;
  className?: string;
  children: ReactNode;
};

const SummaryEditLink: FunctionComponent<SummaryEditLinkProps> = ({ to, dataQa, className, children }) => {
  const { data } = useFetchCheckoutQuery();
  const [mutateTip] = useMutateTipMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const totalAmountInPounds = getGQLTotalAmountInPounds(data);
  const [tipAmount, totalAmountToPay] = useWatch<
    SummaryPageFormValues & AmountPageFormValues,
    ['tipAmount', 'totalAmountToPay']
  >({
    name: ['tipAmount', 'totalAmountToPay'],
  });

  const saveThenNavigate = async (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());

    const isTotalDifferent = totalAmountToPay && totalAmountToPay !== totalAmountInPounds;
    try {
      if (isTotalDifferent) {
        await mutateTip({ tipValue: tipAmount });
      }
    } catch (error) {
      // Attempt to save tip but continue if failed to edit
    }
    dispatch(endGlobalLoading());
    navigate(to);
  };

  return (
    <a href={to} onClick={saveThenNavigate} data-qa={dataQa} className={className}>
      {children}
    </a>
  );
};

export default SummaryEditLink;
