import React, { FunctionComponent } from 'react';

import classNames from 'classnames';

import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCurrencyCode } from '../../redux/checkoutApiSlice/selectors/order.selectors';
import { getGQLCryptoDetails } from '../../redux/checkoutApiSlice/selectors/payment.selectors';
import { formatCurrency } from '../../utils/value';
import * as styles from './CryptoValues.css';
import { calculateCryptoAmount } from './cryptoUtils';

type Props = {
  fiatAmount: number;
  accessibleTitle?: string;
  isBranded?: boolean;
  isThankYouPage?: boolean;
};

const CryptoValues: FunctionComponent<Props> = ({ fiatAmount, accessibleTitle, isBranded, isThankYouPage }) => {
  const { data } = useFetchCheckoutQuery();
  const currencyCode = getGQLCurrencyCode(data);
  const { estimatedExchangeRate, currencyShortName } = getGQLCryptoDetails(data)!;
  const amount = formatCurrency(currencyCode, fiatAmount);
  const cryptoAmount = calculateCryptoAmount(fiatAmount, estimatedExchangeRate);

  if (isThankYouPage) return <div>{`${cryptoAmount}${currencyShortName} (${amount})`}</div>;

  return (
    <>
      <div className={styles.wrapper} aria-hidden>
        <div className={classNames(styles.cryptoAmount, { [styles.isBranded]: isBranded })}>
          {cryptoAmount} <span className={styles.currency}>{currencyShortName}</span>
        </div>
        <span className={styles.amount}>{amount}</span>
      </div>
      <VisuallyHidden>{`${accessibleTitle ? `${accessibleTitle}: ` : ''}${cryptoAmount} ${currencyShortName} (${amount})`}</VisuallyHidden>
    </>
  );
};

export default CryptoValues;
