import React, { FunctionComponent, useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '@justgiving/auth-react';
import { useDecision } from '@optimizely/react-sdk';

import { AmountPageFormValues, TransactionTypes } from '../../../../types/formValues';
import signUpIcon from '../../../assets/images/signUpIcon.svg';
import { sprinkles } from '../../../styles/sprinkles.css';
import Divider from '../../components/Divider/Divider';
import { Image } from '../../components/Image/Image';
import VisuallyHidden from '../../components/VisuallyHidden/VisuallyHidden';
import Button from '../../components/button/Button';
import ButtonTextLink from '../../components/button/ButtonTextLink';
import { getAbTestKey } from '../../config/abTests';
import { OnSumbitOptions } from '../../pages/AmountPage/AmountPage';
import { fetchCryptocurrencies } from '../../redux/checkoutApiSlice/fetchCryptocurrencies';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsBBGF, getGQLIsDonorPaysFeesEnabled } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';

type Props = { onSubmit: (options?: OnSumbitOptions) => (e?: React.BaseSyntheticEvent) => Promise<void> };

const AmountContinue: FunctionComponent<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
  } = useFormContext<AmountPageFormValues>();

  const { data } = useFetchCheckoutQuery();
  const isDonorPaysFeesEnabled = getGQLIsDonorPaysFeesEnabled(data);
  const auth = useContext(AuthContext);

  const transactionType = useWatch<AmountPageFormValues, 'transactionType'>({
    name: 'transactionType',
  });

  const { isLoading, isFetching, isError } = fetchCryptocurrencies.endpoints.fetchCryptocurrencies.useQueryState();
  const isCryptoLoading = transactionType === TransactionTypes.CRYPTO && (isLoading || isFetching);
  const isCryptoError = transactionType === TransactionTypes.CRYPTO && isError;

  const [{ enabled }] = useDecision(
    getAbTestKey({ abTest: 'DIRECT_DEBIT_LOGIN', shouldEnrol: transactionType === TransactionTypes.RECURRING }),
  );

  const isBBGF = getGQLIsBBGF(data);

  const donateButtonDescribedBy = [
    isDonorPaysFeesEnabled ? 'donation-total-amount' : '',
    isBBGF ? 'bbgf-terms' : '',
  ].join(' ');

  if (transactionType === TransactionTypes.RECURRING && enabled && !auth?.isUserLoggedIn())
    return (
      <div className={sprinkles({ textAlign: 'center' })}>
        <Button
          className={sprinkles({ marginTop: 'sizeSpacing06', marginBottom: 'sizeSpacing04' })}
          isLoading={isSubmitting}
          disabled={isSubmitting || Object.keys(errors).length > 0}
        >
          {t('directDebit|signUp', { ns: 'ab' })}
        </Button>
        <Divider>{t('or')}</Divider>
        <ButtonTextLink
          type="button"
          className={sprinkles({ marginTop: 'sizeSpacing04' })}
          onClick={onSubmit({ redirectToLogin: true })}
          disabled={isSubmitting || Object.keys(errors).length > 0}
        >
          {t('directDebit|login', { ns: 'ab' })}
        </ButtonTextLink>
        <div
          className={sprinkles({
            textAlign: 'left',
            display: 'flex',
            gap: 'sizeSpacing02',
            alignItems: 'start',
            marginTop: 'sizeSpacing06',
          })}
        >
          <Image src={signUpIcon} />
          <div>
            <span>{t('directDebit|why|heading', { ns: 'ab' })}</span>
            <p className={sprinkles({ color: 'light' })}>{t('directDebit|why|info', { ns: 'ab' })}</p>
          </div>
        </div>
      </div>
    );

  return (
    <Button
      className={sprinkles({ marginTop: 'sizeSpacing06' })}
      disabled={isSubmitting || isCryptoError || Object.keys(errors).length > 0}
      isLoading={isSubmitting || isCryptoLoading}
      qaSelector="amountPageContinueButton"
      aria-describedby={donateButtonDescribedBy}
    >
      {t('continueLabel')} <VisuallyHidden>{t('continueLabelAccessibleExtension')}</VisuallyHidden>
    </Button>
  );
};

export default AmountContinue;
