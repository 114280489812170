import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';

import { AmountPageFormValues } from '../../../../types/formValues';
import { sprinkles } from '../../../styles/sprinkles.css';
import Divider from '../../components/Divider/Divider';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useTipJarModeConfig } from '../../hooks/useTipJarModeConfig';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLisTipJarEnabled } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import { getTipIsReady, getTipInputMode } from '../../redux/tip/tip.selectors';
import { TipJarAmountWatcher } from './TipJarAmountWatcher';
import { TipJarBox } from './TipJarBox';
import TipJarRadioGroup from './TipJarRadioGroup';
import { TipJarSlider } from './TipJarSlider';
import useInitTipjar from './useInitTipjar';

type TipJarProps = {
  hide?: boolean;
};

const TipJar: FunctionComponent<TipJarProps> = ({ hide = false }) => {
  const { register } = useFormContext<AmountPageFormValues>();
  const { showTipOn, showTipAs } = useTipJarModeConfig();

  useInitTipjar();

  const tipInputMode = useAppSelector(getTipInputMode);
  const isTipReady = useAppSelector(getTipIsReady);

  const isOtherSelected = tipInputMode === 'Other';
  if (!isTipReady) return null;

  return (
    <>
      <TipJarAmountWatcher />
      {!hide && isOtherSelected && showTipOn === 'summary' && <Divider as="div" />}
      <div
        className={classNames({
          [sprinkles({ display: 'none' })]: hide || (isOtherSelected && showTipOn === 'summary'),
        })}
      >
        <TipJarBox>
          {showTipAs === 'slider' && <TipJarSlider />}
          {showTipAs === 'radios' && <TipJarRadioGroup />}
          <input {...register('tipAmount', { valueAsNumber: true })} hidden />
        </TipJarBox>
      </div>
    </>
  );
};

export const TipJarWrapper: FunctionComponent<TipJarProps> = ({ hide = false }) => {
  const { data } = useFetchCheckoutQuery();

  const isTipJarEnabled = getGQLisTipJarEnabled(data);
  return isTipJarEnabled ? <TipJar hide={hide} /> : null;
};
