import React from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { Severity } from '@sentry/types/dist/severity';

import { useFetchCheckoutQuery } from '../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLIsTransactionComplete } from '../redux/checkoutApiSlice/selectors/transaction.selectors';
import { getSessionCheckoutId } from '../redux/session/session.selectors';
import { getCheckoutId } from '../utils/getCheckoutId';
import { useAppSelector } from './reduxHooks';

export const useWarnUserBeforeNavigation = () => {
  const sessionCheckoutId = useAppSelector(getSessionCheckoutId);
  const { isTransactionComplete } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        isTransactionComplete: getGQLIsTransactionComplete(data),
      };
    },
  });

  useBeforeUnload(
    React.useCallback(
      event => {
        let cookieCheckoutId: string;
        try {
          cookieCheckoutId = getCheckoutId();
        } catch (error) {
          cookieCheckoutId = '';
        }
        // Trigger browser default "Changes may not be saved" message with cancel option on navigation/refresh - only if Redux state checkout ID and cookie checkout ID do not match, and transaction is not complete
        if (sessionCheckoutId !== cookieCheckoutId && !isTransactionComplete) {
          event.preventDefault();

          try {
            window.Sentry?.captureMessage('User was alerted to unsaved data in the checkout', {
              level: Severity.Warning,
              extra: { sessionCheckoutId, cookieCheckoutId },
            });
          } catch (err) {
            // Do nothing
          }
        }
        return null;
      },
      [sessionCheckoutId, isTransactionComplete],
    ),
  );
};
