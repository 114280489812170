import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { LineItemType } from '../../../../types/__generated-graphQL__';
import DonationTargetBar from '../../components/DonationTargetBar/DonationTargetBar';
import { usePageName } from '../../hooks/usePageName';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import {
  getGQLCharityName,
  getGQLPageOwnerName,
  getGQLCampaignName,
} from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import { getGQLOrderType, getGQLPage } from '../../redux/checkoutApiSlice/selectors/lineItem.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import ExpiryMessage from '../ExpiryMessage/ExpiryMessage';
import ThankYouInAdvance from '../ThankYouInAdvance/ThankYouInAdvance';
import * as styles from './Header.css';
import HeaderImage from './HeaderImage';

const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const { accordionEnabled } = useAccordionExperiment();
  const { orderType, pageOwnerName, campaignName, charityName, pageSubtitle } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        orderType: getGQLOrderType(data),
        pageOwnerName: getGQLPageOwnerName(data),
        campaignName: getGQLCampaignName(data),
        charityName: getGQLCharityName(data),
        pageSubtitle: getGQLPage(data)?.subtitle,
      };
    },
  });

  // Note: "unclaimed" pages for Digital Services projects have the name "- -", which is suppressed by the selector
  const isExpectedFundraiserNameMissing = Boolean(
    orderType &&
      [LineItemType.DonationCampaignFrp, LineItemType.DonationCharityFrp].includes(orderType) &&
      !pageOwnerName,
  );

  const pageName = usePageName();
  if (pageName === 'ThankYouPage') return null;

  return (
    <header>
      <div className={accordionEnabled ? styles.accordionWrapper : styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.profile}>
            <div className={styles.image}>
              <HeaderImage />
            </div>
            <p>
              {t('donateTo', {
                name: pageOwnerName ?? campaignName ?? charityName,
                ns: 'page',
              })}
            </p>
            {orderType !== LineItemType.DonationCharityDirect && !isExpectedFundraiserNameMissing && (
              <p className={styles.extra}>
                {orderType === LineItemType.DonationCrowdfunding
                  ? pageSubtitle
                  : t(`fundraising|for`, {
                      name: charityName,
                      ns: 'page',
                    })}
              </p>
            )}
          </div>
          <DonationTargetBar />
          <ThankYouInAdvance />
          <ExpiryMessage />
        </div>
      </div>
    </header>
  );
};

export default Header;
