import React, { FunctionComponent } from 'react';

import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLPageOwnerOrCharityName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import useAccordionExperiment from '../AccordionPages/useAccordionExperiment';
import HeaderImage from '../Header/HeaderImage';
import SourcePagePreviewCover from './SourcePagePreviewCover';
import * as styles from './SourcePagePreviewHeader.css';

const SourcePagePreviewHeader: FunctionComponent = () => {
  const { pageOwnerOrCharityName } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return { pageOwnerOrCharityName: getGQLPageOwnerOrCharityName(data) };
    },
  });
  const { accordionEnabled } = useAccordionExperiment();

  return (
    <>
      <SourcePagePreviewCover />
      <div className={accordionEnabled ? styles.accordionHeaderInfo : styles.headerInfo}>
        <HeaderImage className={styles.causeImage} size={accordionEnabled ? 'mediumAccordion' : 'large'} />
        <div className={styles.name}>{pageOwnerOrCharityName}</div>
      </div>
    </>
  );
};

export default SourcePagePreviewHeader;
