import React, { FunctionComponent } from 'react';

import classNames from 'classnames';

import AvatarPlaceholder from '../../../assets/images/avatarPlaceholder.svg';
import { Image } from '../../components/Image/Image';
import { useFetchPageQuery } from '../../redux/additionalPageDataApiSlice/additionalPageDataApiSlice';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLAdditionalDataCause } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getGQLPageOwnerName } from '../../redux/checkoutApiSlice/selectors/complex.selectors';
import buildAvatarUrl from '../../utils/buildAvatarUrl';
import * as styles from './HeaderImage.css';

type Props = {
  className?: string;
  size?: 'smallAbScaling' | 'mediumAccordion' | 'large';
};

const HeaderImage: FunctionComponent<Props> = ({ className, size }) => {
  const { pageOwnerName, additionalDataCauseImage } = useFetchCheckoutQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        pageOwnerName: getGQLPageOwnerName(data),
        additionalDataCauseImage: getGQLAdditionalDataCause(data)?.image,
      };
    },
  });
  const { pageOwnerAvatar } = useFetchPageQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        pageOwnerAvatar: data?.owner?.avatar,
      };
    },
  });

  const classesAvatar = classNames(
    {
      [styles.imageAvatarSmall]: size === 'smallAbScaling',
      [styles.imageAccordionAvatarMedium]: size === 'mediumAccordion',
      [styles.imageAvatar]: !size || size === 'large',
    },
    className,
  );

  const classesLogo = classNames(
    {
      [styles.imageLogoSmall]: size === 'smallAbScaling',
      [styles.imageAccordionLogoMedium]: size === 'mediumAccordion',
      [styles.imageLogo]: !size || size === 'large',
    },
    className,
  );

  if (pageOwnerName) {
    return (
      <Image
        data-qa="header-avatar"
        className={classesAvatar}
        src={pageOwnerAvatar ? buildAvatarUrl(pageOwnerAvatar, false) : AvatarPlaceholder}
      />
    );
  } else if (additionalDataCauseImage) {
    return <Image data-qa="header-logo" className={classesLogo} src={buildAvatarUrl(additionalDataCauseImage, true)} />;
  } else {
    return <Image data-qa="header-avatar-fallback" className={classesAvatar} src={AvatarPlaceholder} />;
  }
};

export default HeaderImage;
