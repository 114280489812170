import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { heading } from '../../../styles/recipes/headings.css';
import { sprinkles } from '../../../styles/sprinkles.css';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useHeadingSetFocusRef } from '../../hooks/useHeadingSetFocusRef';
import useAccordionExperiment from '../../modules/AccordionPages/useAccordionExperiment';
import { useFetchCheckoutQuery } from '../../redux/checkoutApiSlice/queryCheckoutApi';
import { getGQLCheckoutMode } from '../../redux/checkoutApiSlice/selectors/additionalData.selectors';
import { getRouterHistory } from '../../router/router.selectors';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import * as styles from './pageHeading.css';

type PageHeadingProps = {
  children: ReactNode;
  hideBack?: boolean;
};

export const PageHeading: FunctionComponent<PageHeadingProps> = ({ children, hideBack }) => {
  const { data } = useFetchCheckoutQuery();
  const headingRef = useHeadingSetFocusRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const checkoutMode = getGQLCheckoutMode(data);
  const routerHistory = useAppSelector(getRouterHistory);
  const { accordionEnabled } = useAccordionExperiment();

  const showBackButton = !hideBack && routerHistory.length > 1 && checkoutMode === 'Headless' && !accordionEnabled;

  return (
    <div className={classNames(styles.wrapper, { [styles.isBackButton]: showBackButton })}>
      {showBackButton && (
        <button className={styles.back} onClick={() => navigate(-1)} type="button" title={t('goBack')}>
          <VisuallyHidden>{t('goBack')}</VisuallyHidden>
        </button>
      )}
      <h1
        ref={headingRef}
        tabIndex={-1}
        data-qa="h1-header"
        className={classNames(
          sprinkles({ textAlign: 'center', marginTop: { mobile: 'sizeSpacing04', desktop: 'sizeSpacing05' } }),
          heading({ level: 1 }),
        )}
      >
        {children}
      </h1>
    </div>
  );
};
