import { Reducer } from 'redux';

import { getCheckoutId } from '../../utils/getCheckoutId';
import { SessionActionTypes } from './session.actions';

let checkoutId: string;
try {
  checkoutId = getCheckoutId();
} catch (error) {
  checkoutId = '';
}

const initialState = {
  paymentCardsWithCVV: [],
  summaryPageReady: false,
  globalLoading: false,
  isEmbedded: false,
  isBraintreeInitialised: false,
  isBraintreeDataCollectorInitialised: false,
  checkoutId,
};

export const session: Reducer<State['session'], SessionActionTypes> = (
  state = initialState,
  action: SessionActionTypes,
) => {
  switch (action.type) {
    case 'session/updatePaymentCardsWithCVV': {
      if (state.paymentCardsWithCVV?.indexOf(action.paymentCard) === -1) {
        return {
          ...state,
          paymentCardsWithCVV: [...state.paymentCardsWithCVV, action.paymentCard],
        };
      }
      return state;
    }
    case 'session/summaryPageReady': {
      return {
        ...state,
        summaryPageReady: true,
      };
    }
    case 'session/startGlobalLoading': {
      return {
        ...state,
        globalLoading: true,
      };
    }
    case 'session/endGlobalLoading': {
      return {
        ...state,
        globalLoading: false,
      };
    }
    case 'session/updateIsEmbedded': {
      return {
        ...state,
        isEmbedded: true,
      };
    }
    case 'session/updateErrorType': {
      return {
        ...state,
        errorType: action.errorType,
      };
    }
    case 'session/braintreeInitialised': {
      return {
        ...state,
        isBraintreeInitialised: true,
      };
    }
    case 'session/braintreeDataCollectorInitialised': {
      return {
        ...state,
        isBraintreeDataCollectorInitialised: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default session;
