import { FunctionComponent, useContext, useEffect } from 'react';

import { AuthContext } from '@justgiving/auth-react';

import { useAppSelector } from '../../hooks/reduxHooks';
import { getIsLoggedIn } from '../../redux/auth/auth.selectors';
import { useFetchPreferences } from '../../redux/preferencesApiSlice/preferencesApiSlice';
import { setSiftScienceUserId } from '../../utils/vendorAPIs';

const HandleLogin: FunctionComponent = () => {
  const isLoggedin = useAppSelector(getIsLoggedIn);
  const auth = useContext(AuthContext);

  useFetchPreferences(!isLoggedin);

  useEffect(() => {
    if (isLoggedin) {
      setSiftScienceUserId(auth?.getAccessToken()?.guid);
    }
  }, [auth, isLoggedin]);

  return null;
};

export default HandleLogin;
